var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"rounded-lg",attrs:{"flat":""}},[_c('v-container',{staticClass:"px-7 py-4",attrs:{"fluid":""}},[_c('v-row',{staticClass:"d-flex justify-center"},[_c('v-col',{attrs:{"cols":"9"}},[_c('div',{staticClass:"headline font-weight-bold font--text"},[_vm._v("List Region")])]),_c('v-col',{attrs:{"md":"1"}}),_c('v-col',{attrs:{"md":"2"}},[(!_vm.read_only)?_c('v-btn',{staticClass:"secondary",attrs:{"id":"CreateNewType","depressed":"","height":"50","width":"150"},on:{"click":function () {
                    _vm.selectedRegion = null;
                    _vm.openDialogAdd = true;
                  }}},[_vm._v("Create New Region")]):_vm._e()],1)],1),_c('v-row',[_c('v-col',{attrs:{"md":"12"}},[_c('v-data-table',{attrs:{"id":"my-table","hide-default-footer":"","headers":_vm.headers,"items-per-page":_vm.regionPaginator.limit,"search":_vm.search,"items":_vm.regions,"options":_vm.options},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.region_name",fn:function(ref){
                  var item = ref.item;
return [_vm._v(" "+_vm._s(item.region_name ? item.region_name : "-")+" ")]}},{key:"item.name",fn:function(ref){
                  var item = ref.item;
return [_vm._v(" "+_vm._s(item.name ? item.name : "-")+" ")]}},{key:"item.description",fn:function(ref){
                  var item = ref.item;
return [_vm._v(" "+_vm._s(item.description ? item.description : "-")+" ")]}},{key:"item.volume_limit",fn:function(ref){
                  var item = ref.item;
return [_c('span',{class:{ 'error--text': item.volume_usage >= item.volume_limit }},[_vm._v(" "+_vm._s(item.volume_limit ? ((item.volume_usage) + "/" + (item.volume_limit) + " GB") : "-")+" ")])]}},{key:"item.status",fn:function(ref){
                  var item = ref.item;
return [_c('div',{staticClass:"min-width-130"},[_c('v-switch',{attrs:{"flat":"","readonly":"","disabled":_vm.read_only,"input-value":item.status},on:{"click":function () {
                          if (_vm.read_only) {
                            return;
                          }
                          _vm.regionselectedvalue = item;
                          _vm.opendialogactivateregion = true;
                        }}})],1)]}},{key:"item.action",fn:function(ref){
                        var item = ref.item;
return [_c('popupquote',{attrs:{"offsetTop":21,"documentid":'popup' + item.id,"offset-y":"","allow-overflow":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c('label',_vm._b({staticClass:"primary--text linkpointer",staticStyle:{"position":"relative","white-space":"nowrap"},on:{"click":function ($event) {
                            on.click($event);
                            item.open = true;
                          }}},'label',attrs,false),[_vm._v("More "),_c('v-icon',{class:{
                            rotate: item.open,
                          },attrs:{"id":'popup' + item.id,"color":"primary"}},[_vm._v("mdi-chevron-down ")])],1)]}}],null,true),model:{value:(item.open),callback:function ($$v) {_vm.$set(item, "open", $$v)},expression:"item.open"}},[_c('v-list',[_c('v-list-item',{attrs:{"disabled":_vm.read_only},on:{"click":function () {
                            _vm.selectedRegion = item;
                            _vm.openDialogAdd = true;
                          }}},[_c('v-list-item-title',[_vm._v("Edit")])],1),_c('v-list-item',{on:{"click":function () {
                            _vm.selectedRegion = item;
                            _vm.openDialogDetail = true;
                          }}},[_c('v-list-item-title',[_vm._v("Detail")])],1),_c('v-list-item',{staticStyle:{"min-height":"1px","margin-left":"-16px","margin-right":"-16px"}},[_c('v-divider',{staticStyle:{"min-width":"160px"}})],1),_c('v-list-item',{attrs:{"disabled":_vm.read_only},on:{"click":function () {
                            _vm.selectedRegion = item;
                            _vm.openDialogDelete = true;
                          }}},[_c('v-list-item-title',{staticClass:"error--text delete"},[_vm._v("Delete ")])],1)],1)],1)]}},{key:"footer",fn:function(ref){
                          var props = ref.props;
return [_c('custom-footer-datatable',{attrs:{"props":props},on:{"input":function (val) {
                        _vm.options = Object.assign({}, _vm.options, val);
                      }}})]}}],null,true)})],1)],1)],1)],1)],1)],1),_c('dialogAdd',{attrs:{"selectedRegion":_vm.selectedRegion,"formState":_vm.openDialogAdd},on:{"create":function (payload) { return _vm.doCreate(payload); },"update":function (payload) { return _vm.doUpdate(payload); }},model:{value:(_vm.openDialogAdd),callback:function ($$v) {_vm.openDialogAdd=$$v},expression:"openDialogAdd"}}),(_vm.openDialogDetail)?_c('dialogDetail',{attrs:{"selectedRegion":_vm.selectedRegion},model:{value:(_vm.openDialogDetail),callback:function ($$v) {_vm.openDialogDetail=$$v},expression:"openDialogDetail"}}):_vm._e(),_c('dialogDelete',{attrs:{"selectedRegion":_vm.selectedRegion},on:{"delete":function (payload) { return _vm.doDelete(payload); }},model:{value:(_vm.openDialogDelete),callback:function ($$v) {_vm.openDialogDelete=$$v},expression:"openDialogDelete"}}),(_vm.opendialogactivateregion)?_c('dialogActivateRegion',{attrs:{"data-status":_vm.regionselectedvalue,"open-dialog":_vm.opendialogactivateregion},on:{"update:openDialog":function($event){_vm.opendialogactivateregion=$event},"update:open-dialog":function($event){_vm.opendialogactivateregion=$event}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }