<template>
  <div>
    <!-- top white box (Balance) -->
    <BalanceAndHistoryBtn v-if="balance" :balance="balance" />

    <!-- bottom white box (Choose Payment) -->    
    <v-card flat class="rounded-lg pa-4 mt-3" v-if="!read_only">
      <v-card-text>
        <p class="fz-21 font-weight-bold">Choose Payment</p>

      </v-card-text>

      <v-card-text class="pt-0">
        <v-row>
          <!-- proj name -->
          <v-col cols="6">
            <p><b>Project Name</b></p>
            <v-text-field
              v-model="project.name"
              outlined
              single-line
              label="Project"
              disabled
            ></v-text-field>
          </v-col>

          <!-- nominal balance -->
          <v-col v-if="isPrepaid" cols="6">
            <p><b>Nominal Balance</b></p>
            <v-text-field
              id="idr"
              flat
              outlined
              v-model.number="idr"
              @keypress="isNumber($event)"
              placeholder="IDR"
              required
              :disabled="!isUser"
              style="margin-bottom: 22px"
              :error-messages="idrError"
              @focus="additionalpricefocus = true"
              @blur="
                (e) => {
                  additionalpricefocus = false;
                  onChangeHandler(e);
                }
              "

              :class="{ hideinput: additionalpricefocus === false }"
            >
              <template v-slot:prepend-inner>
                <span>
                  <span class="font--text">IDR | </span>
                  <span v-if="!additionalpricefocus">
                    {{ toThousandsWithDotFormat(idr) }}
                  </span>
                </span>
              </template>
            </v-text-field>
          </v-col>

          <v-col v-if="isPostpaid" cols="6">
            <p><b>Outstanding Amount</b></p>
            <v-text-field
              id="idr"
              flat
              outlined
              v-model.number="idr"
              @keypress="isNumber($event)"
              placeholder="IDR"
              required
              disabled
              style="margin-bottom: 22px"
              :error-messages="idrError"
              @blur="onChangeHandler"
              class="hideinput"
            >
              <template v-slot:prepend-inner>
                <span>
                  <span class="font--text">IDR | </span>
                  {{ toThousandsWithDotFormat(idr) }}
                </span>
              </template>
            </v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <!-- choose payment -->
          <v-col>
            <p><b>Choose Payment</b></p>
            <p v-if="paymentError" class="error--text">{{ paymentError }}</p>
            <v-radio-group v-model="payment" row>
              <v-row>
                <v-col
                  v-for="(method, index) in paymentMethods"
                  :key="'payment-method-' + method.value + index"
                  style="font-size: 10px"
                >
                  <v-radio :label="method.label" :value="method.value" :disabled="!isUser">
                    <template v-slot:label>
                      <img
                        v-if="method.icon"
                        :src="require('@/assets/payment-method/' + method.icon)"
                        class="mr-2"
                      />

                      <span class="fs-14">{{ method.label }}</span>
                    </template>
                  </v-radio>
                </v-col>
              </v-row>
            </v-radio-group>
          </v-col>
        </v-row>
      </v-card-text>

      <!-- continue payment button -->
      <v-row>
        <v-col cols="12" sm="12" md="6" />

        <v-col cols="12" sm="12" md="6">
          <v-btn
            id="submit"
            block
            height="50"
            depressed
            color="secondary"
            class="mr-4 text-capitalize font-weight-bold"
            @click="submit"
            :disabled="loading || totalUnpaidBilledError || !payment || !isUser"
          >
            <!-- loading indicator -->
            <beat-loader
              v-if="loading"
              :loading="loading"
              :color="'white'"
              :size="'10px'"
              class="ml-2"
            />

            {{ loading ? "Continue Payment..." : "Continue Payment" }}
          </v-btn>
        </v-col>
      </v-row>
    </v-card>

    <form
      ref="enterPaymentPageForm"
      class="d-none"
      :action="enterPaymentPageRes.payment_url"
      method="POST"
    >
      <input
        type="hidden"
        name="CheckoutID"
        :value="enterPaymentPageRes.checkout_id"
      />
      <input
        type="hidden"
        name="Signature"
        :value="enterPaymentPageRes.signature"
      />
    </form>
  </div>
</template>

<script>
import { useNamespacedState } from "vuex-composition-helpers";
import localstorage from "@/lib/localstorage";
import api from "@/lib/api";
import BalanceAndHistoryBtn from "./payment/BalanceAndHistoryBtn.vue";
import UserInfo from "./payment/UserInfo.vue";
import Outstanding from "./payment/Outstanding.vue";
import { toThousandsWithDotFormat } from "@/lib/formatter";

import { AccessControl } from "@/lib/middleware";

export default {
  components: {
    BalanceAndHistoryBtn,
    UserInfo,
    Outstanding,
  },
  data() {
    return {
      additionalpricefocus: false,
      totalUnpaidBilledError: false,
      totalUnpaidBilled: 0,
      balance: null,
      idr: 0,
      idrError: "",
      payment: null,
      paymentError: "",
      loading: false,
      totalproject: "",
      projectname: "",
      error: false,
      paymentMethods: [],
      enterPaymentPageRes: {
        payment_url: "",
        checkout_id: "",
        signature: "",
      },

      // outstanding update
      projects: [],
      project: {},
      projectError: "",
    };
  },
  computed: {
    currentOrg() {
      const org = localstorage.getItem("currentOrg");

      if (!org) return null;

      return JSON.parse(org);
    },
    isSuperAdmin() {
      const role = localstorage.getItem("role");
      const isSuperAdmin = role && role === "Superadmin";

      return isSuperAdmin;
    },
    isUser() {
      const userrole = localstorage.getItem('role')
      if (userrole == 'Superadmin' || userrole == 'Customer-Care' || userrole == 'GodMode') {
          return false
      }
      return true
    },
    isPrepaid() {
      return this.project.payment_method === "prepaid"
    },
    isPostpaid() {
      return this.project.payment_method === "postpaid"
    },
  },
  setup(props, context) {
    const access_control = new AccessControl(context.root.$store);
    const read_only = access_control.is_read_mode('Balance');
    const { userprofile } = useNamespacedState("ROLEPERMISSION", [
      "userprofile",
    ]);    

    return {
      read_only,
      userprofile,
      toThousandsWithDotFormat,
    };
  },
  methods: {
    isNumber(e) {
      const charCode = e.which ? e.which : e.keyCode;

      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        e.preventDefault();
      } else {
        return true;
      }
    },
    onChangeHandler(e) {
      if (!this[e.target.id] && !this[e.target.id + "Error"]) {
        this[e.target.id + "Error"] = "Value is required";
      }

      if (this[e.target.id] && this[e.target.id + "Error"]) {
        this[e.target.id + "Error"] = "";
      }
    },
    async submit() {
      // if nominal balance field is empty
      const requiredFields = ["idr", "payment", "project"];
      const noEmptyField = requiredFields.every((field) => this[field]);

      // if (!this.idr) {
      //   this.idrError = "Value is required";
      // } else if (!this.payment) {
      //   this.paymentError = "Value is required";
      // } else if (!this.project) {
      //   this.projectError = "Value is required";
      // } else {

      if (noEmptyField) {
        this.loading = true;

        const endpoint = this.isPrepaid
          ? "/payment/deposit"
          : "/payment/postpaid";

        const payload = {
          payment_id: this.payment.toString(),
          amount: this.idr.toString(),
          project_id: this.project.id,
        };

        try {
          console.log("sending to :", endpoint)
          console.log("with payload :", payload)
          const res = await api.POST(endpoint, payload);
          console.log("response :", res)
          console.log("response data :", res.data)

          this.enterPaymentPageRes = res.data;
        } catch (err) {
          let errmsg = "Sorry, there was an error while submitting data";

          if (err.response && err.response.data && err.response.data.data) {
            errmsg = err.response.data.data;
          }

          this.$store.dispatch("HOMEPAGE/showErrorToast", errmsg);
        }

        this.loading = false;
      } else {
        requiredFields.forEach((field) => {
          if (!this[field]) {
            this[field + "Error"] = "Value is required";
          }
        });
      }
    },
    async fetchBalance() {
      if (this.currentOrg) {
        this.loading = true;

        try {
          const endpoint = this.isSuperAdmin
            ? "/superadmin/balance/accumulated/" + this.currentOrg.id + "/" + localStorage.projectid
            : "/balance/accumulated/" + localStorage.projectid;
          const balance = await api.GET(endpoint);
          this.balance = balance.data

          // set nominal to project outstanding
          if (this.isPostpaid) {
            this.idr = this.balance.total_outstanding
          }

        } catch {
          this.$store.dispatch(
            "HOMEPAGE/showErrorToast",
            "Sorry, an error occurred while displaying The Remaining Balance"
          );
        }

        this.loading = false;
      }
    },
    async getPaymentMethods() {
      try {
        const res = await api.GET("/payment/id");
        this.paymentMethods = res.data.map((method) => {
          const { id, name, icon, is_active } = method;

          return {
            label: name,
            value: id,
            icon,
            is_active,
          };
        });
      } catch {
        this.$store.dispatch(
          "HOMEPAGE/showErrorToast",
          "Sorry, an error occurred while getting payment methods"
        );
      }
    },
  },
  mounted() {
    const currentProj = localstorage.getItem("currentProj");
    this.project = JSON.parse(currentProj)

    this.getPaymentMethods();
    this.fetchBalance();
  },
  watch: {
    idr() {
      this.onChangeHandler({ target: { id: "idr" } });
    },
    payment() {
      this.onChangeHandler({ target: { id: "payment" } });
    },
    project(newVal) {
      this.idr = 0;
      this.onChangeHandler({ target: { id: "project" } });
    },
    "enterPaymentPageRes.signature"() {
      console.log("redirect to payment gateway page...", this.enterPaymentPageRes)
      setTimeout(() => {
        this.$refs.enterPaymentPageForm.submit();
      }, 1000);
    },
  },
};
</script>

<style lang="scss" scoped>
.fs-14 {
  font-size: 14px !important;
}

.hideinput {
  ::v-deep input {
    position: relative;
    bottom: 50px;
    z-index: -2;
  }
}
</style>
