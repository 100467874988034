var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card-text',[_c('v-container',[_c('v-col',{staticClass:"justify-center"},[_c('h1',{staticStyle:{"text-align":"center","font-weight":"800","font-size":"28px","line-height":"36px","letter-spacing":"0.04em","font-family":"Mont","font-style":"normal"}},[_vm._v(" 1. CREATE ORGANIZATION ")]),_c('p',{staticClass:"text-center mt-5",staticStyle:{"font-size":"20px","color":"#a5b3bf","line-height":"27.24px"}},[_vm._v(" You have to create an organization before using the app ")]),_c('v-form',{ref:"form",staticStyle:{"margin-top":"40px"},attrs:{"lazy-validation":""}},[(_vm.errorMessage)?_c('v-alert',{attrs:{"color":"red","type":"error"}},[_vm._v(_vm._s(_vm.errorMessage)+" ")]):_vm._e(),_c('v-text-field',{staticStyle:{"margin-bottom":"22px"},attrs:{"id":"name","flat":"","outlined":"","maxlength":"30","placeholder":"Name of Organization","required":"","error-messages":_vm.nameValidation.$errors.length
              ? _vm.nameValidation.$errors[0].$message
              : ''},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('v-textarea',{staticStyle:{"margin-bottom":"22px"},attrs:{"rows":"5","id":"details","flat":"","outlined":"","maxlength":"255","placeholder":"Details","required":"","error-messages":_vm.detailsValidation.$errors.length
              ? _vm.detailsValidation.$errors[0].$message
              : ''},model:{value:(_vm.details),callback:function ($$v) {_vm.details=$$v},expression:"details"}}),_c('v-textarea',{staticStyle:{"margin-bottom":"22px"},attrs:{"rows":"5","id":"description","flat":"","outlined":"","maxlength":"255","placeholder":"Address","required":"","error-messages":_vm.addressValidation.$errors.length
              ? _vm.addressValidation.$errors[0].$message
              : ''},model:{value:(_vm.address),callback:function ($$v) {_vm.address=$$v},expression:"address"}}),_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:([/[1-9]/, /[0-9]/, /[0-9]/, ' ' ,/[0-9]/, /[0-9]/, /[0-9]/, ' ' ,/[0-9]/, /[0-9]/, /[0-9]/,' ' ,/[0-9]/, /[0-9]/, /[0-9]/]),expression:"[/[1-9]/, /[0-9]/, /[0-9]/, ' ' ,/[0-9]/, /[0-9]/, /[0-9]/, ' ' ,/[0-9]/, /[0-9]/, /[0-9]/,' ' ,/[0-9]/, /[0-9]/, /[0-9]/]"}],staticStyle:{"margin-bottom":"22px"},attrs:{"flat":"","outlined":"","placeholder":"Phone Number","required":"","prefix":"+62","error-messages":_vm.phoneValidation.$errors.length
              ? _vm.phoneValidation.$errors[0].$message
              : ''},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}}),_c('v-autocomplete',{staticStyle:{"margin-bottom":"22px"},attrs:{"outlined":"","items":_vm.saleses,"error-messages":"","placeholder":"Select Sales","item-text":"fullname","item-value":"id"},model:{value:(_vm.sales_id),callback:function ($$v) {_vm.sales_id=$$v},expression:"sales_id"}}),_c('v-btn',{staticClass:"mr-4 mt-7 text-capitalize font-weight-bold",attrs:{"disabled":_vm.isLoading ? true : false,"id":"submit","block":"","height":"50","depressed":"","color":"secondary"},on:{"click":_vm.save}},[_vm._v(" "+_vm._s(_vm.isLoading ? "Please wait..." : "Create")+" ")]),_c('br')],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }