<template>
  <div>
    <!-- contoh template -->
    <v-row>
      <v-col cols="12" class="pt-3">
        <v-card flat class="rounded-lg">
          <v-container fluid class="pa-7">
            <v-row>
              <v-col cols="12">
                <div class="headline font-weight-bold font--text">Create Object Storage</div>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="pb-0" cols="12">
                <p>
                  <span class="font-weight-bold mb-0">Select Volume Size</span>
                  <span
                    :class="{
                      'error--text': $v.selectedS3Flavor.$errors.length,
                    }"
                    class="ml-2"
                    style="font-size: 10px; position: absolute"
                    v-if="$v.selectedS3Flavor.$errors.length"
                  >
                    *{{ $v.selectedS3Flavor.$errors[0].$message }}</span
                  >
                </p>
              </v-col>
              <v-slide-group class="py-4 pt-0" active-class="success" show-arrows>
                <v-col class="d-flex flex-row flex-wrap">
                  <v-card
                    tabindex="0"
                    v-if="defaultFlavorObjectStorage"
                    min-width="170"
                    :class="{
                      bordererror: $v.selectedS3Flavor.$errors.length,
                      highlight: isCustomQuotaHighlighted,
                      selected: isCustomQuotaHighlighted,
                    }"
                    :key="defaultFlavorObjectStorage.id"
                    @click="
                      () => {
                        isCustomQuotaHighlighted = true;
                        selectedS3Flavor = null;
                      }
                    "
                    border-color="primary"
                    outlined
                    class="rounded-lg select-instance mb-4"
                    style="text-align: center; margin-right: 20px"
                  >
                    <v-card-text class="pb-0 pt-4 d-flex flex-column m-0">
                      <div v-if="objectStorageDiscount && objectStorageDiscount > 0 && customQuota">
                        <p class="body-2 mb-0" style="text-decoration: line-through">{{ toIDRWithDotFormat(calculateCustomObjectStorage()) }}/mo</p>
                        <p class="font-weight-bold blue--text mb-1">{{ toIDRWithDotFormat(calculateCustomObjectStorage(objectStorageDiscount)) }}/mo</p>

                        <p class="body-2 mb-0" style="text-decoration: line-through">
                          {{ toIDRWithDotFormat(calculateCustomObjectStorage(undefined, "hour")) }}/hour
                        </p>
                        <p class="font-weight-bold blue--text">{{ toIDRWithDotFormat(calculateCustomObjectStorage(objectStorageDiscount, "hour")) }}/hour</p>
                      </div>
                      <div v-else>
                        <p class="font-weight-bold mb-0">{{ toIDRWithDotFormat(calculateCustomObjectStorage()) }}/mo</p>
                        <p class="font-weight-bold">{{ toIDRWithDotFormat(calculateCustomObjectStorage(undefined, "hour")) }}/hour</p>
                      </div>
                    </v-card-text>
                    <v-divider />
                    <v-card-text class="pa-0">
                      <v-text-field
                        v-model="customQuota"
                        @keydown="(ev) => (customQuota = addGBText(ev, customQuota))"
                        maxlength="50"
                        class="px-4 pt-2 mt-2 label-bold"
                        style="width: 170px"
                        flat
                        label="Enter Size in GB"
                      ></v-text-field>
                    </v-card-text>
                  </v-card>
                  <v-card
                    width="170"
                    :class="{
                      bordererror: $v.selectedS3Flavor.$errors.length,
                      highlight: selectedS3Flavor && selectedS3Flavor.id == storage.id,
                      selected: selectedS3Flavor && selectedS3Flavor.id == storage.id,
                    }"
                    v-for="storage in listFlavorObjectStorage"
                    :key="storage.id"
                    @click="
                      () => {
                        selectedS3Flavor = storage;
                        customQuota = null;
                        isCustomQuotaHighlighted = false;
                      }
                    "
                    border-color="primary"
                    outlined
                    class="rounded-lg select-instance mb-4"
                    style="text-align: center; margin-right: 20px"
                  >
                    <!-- filteredS3Flavors -->
                    <!-- listFlavorObjectStorage -->
                    <v-card-text class="pb-0 pt-5">
                      <div v-if="objectStorageDiscount && objectStorageDiscount > 0">
                        <p class="body-2 mb-0" style="text-decoration: line-through">
                          {{ toIDRWithDotFormat(calculateObjectStorage(storage.price_per_month)) }}/mo
                        </p>
                        <p class="font-weight-bold blue--text mb-1">
                          {{ toIDRWithDotFormat(calculateObjectStorage(storage.price_per_month, objectStorageDiscount)) }}/mo
                        </p>

                        <p class="body-2 mb-0" style="text-decoration: line-through">
                          {{ toIDRWithDotFormat(calculateObjectStorage(storage.price_per_hour)) }}/hour
                        </p>
                        <p class="font-weight-bold blue--text">
                          {{ toIDRWithDotFormat(calculateObjectStorage(storage.price_per_hour, objectStorageDiscount)) }}/hour
                        </p>
                      </div>

                      <div v-else>
                        <p class="font-weight-bold mb-0">{{ toIDRWithDotFormat(calculateObjectStorage(storage.price_per_month)) }}/mo</p>
                        <p class="font-weight-bold">{{ toIDRWithDotFormat(calculateObjectStorage(storage.price_per_hour)) }}/hour</p>
                      </div>
                    </v-card-text>
                    <v-divider />
                    <v-card-text class="d-flex flavor-card">
                      <p class="mb-0">{{ storage.object_storage_size }} GB</p>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-slide-group>
            </v-row>
            <v-row>
              <v-col sm="12" md="6">
                <p class="font-weight-bold">Choose Region</p>
                <v-select
                  id="SelectRegion"
                  outlined
                  placeholder="Choose Region"
                  :items="selectregions"
                  v-model="selectedRegion"
                  :error-messages="$v.selectedRegion.$errors.length ? $v.selectedRegion.$errors[0].$message : ''"
                >
                </v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col sm="12" md="6">
                <p class="font-weight-bold">Billing Type</p>
                <v-select
                  id="SelectRegion"
                  outlined
                  placeholder="Choose Billing Type"
                  :items="billingTypes"
                  v-model="selectedBillingType"
                  :error-messages="$v.selectedS3Flavor.$errors.length ? $v.selectedS3Flavor.$errors[0].$message : ''"
                >
                </v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6" class="pt-0" v-if="selectedBillingType == 'Trial'">
                <p><b>Trial Voucher</b></p>
                <v-select
                  id="trialVoucher"
                  placeholder="Choose trial voucher to use"
                  v-model="selectedTrialVoucher"
                  :error-messages="$v.selectedTrialVoucher.$errors.length ? $v.selectedTrialVoucher.$errors[0].$message : ''"
                  :items="vouchers"
                  item-value="id"
                  outlined
                >
                  <template v-slot:selection="{ item }"> {{ item.name }} - {{ item.code }} </template>
                  <template v-slot:item="{ item }"> {{ item.name }} - {{ item.code }} </template>
                </v-select>
              </v-col>
            </v-row>
            <v-divider class="my-4" />
            <v-row>
              <v-col>
                <p class="font-weight-bold mb-0" style="font-size: 21px">File Listing</p>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="12" md="6">
                <p style="font-size: 12px">
                  <span class="primary--text"><b>Important:</b></span> User who create the object storage or the Creator, will be given an access key to the
                  bucket. Any access to the bucket will require an access key. The Creator can also create new access key to other user in Access Keys menu.
                </p>
              </v-col>
            </v-row>
            <v-divider />
            <v-row>
              <v-col cols="12" sm="12" md="6" class="mt-2">
                <p class="font-weight-bold">Choose a unique name</p>
                <v-text-field
                  maxlength="50"
                  :error-messages="createErrorMessage('objectStorageName')"
                  id="ChooseUniqueName"
                  v-model="objectStorageName"
                  outlined
                  placeholder="Type a unique subdomain name for your bucket"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row v-if="user.role.toLowerCase() !== 'superadmin' && agree2 === false">
              <v-col cols="12">
                <div class="d-flex align-center">
                  <v-checkbox v-model="agree">
                    <template v-slot:label>
                      <div>By Creating Object Storage You Agree To The</div>
                    </template>
                  </v-checkbox>

                  <button type="button" class="text-decoration-underline primary--text ml-2 fz-16" @click="getEula()">Terms of Service</button>
                </div>
              </v-col>
            </v-row>
            <div style="width: auto; height: 10px" />
            <v-divider />
            <div style="width: auto; height: 10px" />
            <v-row>
              <v-col cols="6"><v-btn depressed block height="50" class="accent" to="/object-storage">Cancel</v-btn></v-col>
              <v-col cols="6">
                <v-btn
                  v-if="user.role.toLowerCase() !== 'superadmin'"
                  depressed
                  id="CreateObjectStorage"
                  :disabled="agree === false && agree2 === false"
                  @click="create"
                  block
                  height="50"
                  class="secondary"
                >
                  <beat-loader v-if="isLoading" :loading="isLoading" :color="'white'" :size="'10px'"></beat-loader>
                  <span v-else> Create Object Storage</span>
                </v-btn>
                <v-btn v-else depressed id="CreateObjectStorage" @click="create" block height="50" class="secondary">
                  <beat-loader v-if="isLoading" :loading="isLoading" :color="'white'" :size="'10px'"></beat-loader>
                  <span v-else> Create Object Storage</span>
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import api from "@/lib/api";
const { required, requiredIf, helpers, minLength } = require("@vuelidate/validators");
const useVuelidate = require("@vuelidate/core").default;
import { ref, watch, onMounted, computed } from "@vue/composition-api";
import { useProjects } from "../project/useprojects";
import { useObjectStorage } from "./composableapi/useobjectstorage";
import { useVoucher } from "../voucher/useVoucher";
import { useSelectVolume } from "./useSelectVolume";
import { useRegion } from "./useRegion";
import { toIDRWithDotFormat } from "@/lib/formatter";
import libProject from "@/lib/project";
import { toThousands, addGBText, removeGBText } from "@/lib/formatter";
import localstorage from "@/lib/localstorage";
import { mapGetters } from "vuex";
import { useNamespacedActions, useNamespacedGetters, useNamespacedState } from "vuex-composition-helpers";
import { MANAGEVOUCHER } from "../superadmin-managevoucher/namespace";
import OBJECTSTORAGEBILLING from "./namespace";

export default {
  setup(props, context) {
    props;
    context.root.$router;
    const useComposable = {
      ...useObjectStorage(),
      ...useRegion(),
    };

    const { fetchVoucherDiscountByProjectID } = useNamespacedActions(MANAGEVOUCHER, ["fetchVoucherDiscountByProjectID"]);

    const { voucherDiscountProject } = useNamespacedState(MANAGEVOUCHER, ["voucherDiscountProject"]);
    const { getFlavorObjectStorage, getDefaultObjectStorage } = useNamespacedActions(OBJECTSTORAGEBILLING, [
      "getFlavorObjectStorage",
      "getDefaultObjectStorage",
    ]);
    const { listFlavorObjectStorage, defaultFlavorObjectStorage } = useNamespacedGetters(OBJECTSTORAGEBILLING, [
      "listFlavorObjectStorage",
      "defaultFlavorObjectStorage",
    ]);

    const { fetchVouchers, vouchers } = useVoucher();
    const projJson = localstorage.getItem("currentProj");
    const projObj = projJson && projJson !== "undefined" ? JSON.parse(projJson) : {};
    const postpaid_type = projObj.postpaid_type;

    // const billingTypes = ref(postpaid_type && postpaid_type === 'fixed' ? fixedPostpaid : defaultBillingTypes)
    const billingTypes = ref([]);

    const selectedBillingType = ref("");
    const selectedTrialVoucher = ref(null);

    const defaultBillingTypes = [
      {
        text: "Pay Per Use (PPU)",
        value: "PPU",
      },
      {
        text: "Trial",
        value: "Trial",
      },
    ];

    const fixedPostpaid = [
      {
        text: "Fixed",
        value: "Fixed",
      },
      {
        text: "Trial",
        value: "Trial",
      },
    ];

    const { createObjectStorage, s3Flavors, customQuota, selectedS3Flavor, fetchS3Flavors, s3DefaultPrice, selectedRegion } = useComposable;
    const allowFileListing = ref("disable");
    const objectStorageName = ref("");
    const objectStorageDiscount = ref(0);
    const price_per_month = ref(0);
    const price_per_hour = ref(0);

    const $v = useVuelidate(
      {
        selectedRegion: { required, $autoDirty: true },
        allowFileListing: { required, $autoDirty: true },
        objectStorageName: {
          required,
          minLength: minLength(5),
          $autoDirty: true,
          clean: helpers.withMessage("Can only contains lowercase letters, numbers, and dash.", (val) => {
            return !val.match(/([^a-z0-9-])+/g);
          }),
        },
        selectedTrialVoucher: {
          required: requiredIf((val) => {
            return selectedBillingType.value === "Trial";
          }),
        },
        selectedS3Flavor: {
          required: requiredIf((val) => {
            return customQuota.value === null && selectedS3Flavor.value === null;
          }),
        },
        customQuota: {
          required: requiredIf((val) => {
            return customQuota.value === null && selectedS3Flavor.value === null;
          }),
        },
        selectedBillingType: { required },
      },
      {
        selectedRegion,
        selectedBillingType,
        selectedS3Flavor,
        allowFileListing,
        objectStorageName,
        selectedTrialVoucher,
      }
    );

    async function create() {
      $v.value.$touch();
      if ($v.value.$errors.length) return;

      if (objectStorageDiscount.value) {
        price_per_month.value = customQuota.value ? calculateCustomObjectStorage(objectStorageDiscount.value) : 0;
        // price_per_month.value = customQuota.value
        //   ? calculateCustomObjectStorage(objectStorageDiscount.value)
        //   : calculateObjectStorage(selectedS3Flavor.value.price_per_month, objectStorageDiscount.value);
      } else {
        price_per_month.value = customQuota.value ? parseInt(removeGBText(customQuota.value)) * defaultFlavorObjectStorage.value.price_per_month : 0;
        // price_per_month.value = customQuota.value
        //   ? parseInt(removeGBText(customQuota.value)) * defaultFlavorObjectStorage.value.price_per_month
        //   : selectedS3Flavor.value.price_per_month;
      }

      if (objectStorageDiscount.value) {
        price_per_hour.value = customQuota.value ? calculateCustomObjectStorage(objectStorageDiscount.value, "hour") : 0;
        // price_per_hour.value = customQuota.value
        //   ? calculateCustomObjectStorage(objectStorageDiscount.value, "hour")
        //   : calculateObjectStorage(selectedS3Flavor.value.price_per_hour, objectStorageDiscount.value);
      } else {
        price_per_hour.value = customQuota.value ? parseInt(removeGBText(customQuota.value)) * defaultFlavorObjectStorage.value.price_per_hour : 0;
        // price_per_hour.value = customQuota.value
        //   ? parseInt(removeGBText(customQuota.value)) * defaultFlavorObjectStorage.value.price_per_hour
        //   : selectedS3Flavor.value.price_per_hour;
      }

      let payload = {
        object_storage_region_id: selectedRegion.value.id,
        enable_file_listing: allowFileListing.value === "enable" ? true : false,
        name: objectStorageName.value,
        project_id: await libProject.getActiveProjectID(),
        billing_type: selectedBillingType.value,
        voucher_id: null,
        custom_size: customQuota.value ? parseInt(removeGBText(customQuota.value)) : 0,
        package_object_storage_id: customQuota.value ? defaultFlavorObjectStorage.value.id : selectedS3Flavor.value.id,
        object_storage_service_id: customQuota.value ? defaultFlavorObjectStorage.value.id : undefined
      };

      if (selectedTrialVoucher.value && selectedBillingType.value == "Trial") {
        payload.voucher_id = selectedTrialVoucher.value;
      }

      const response = await createObjectStorage(payload);
      if (response.status === 200) {
        if (this.user.role.toLowerCase() !== "superadmin" && this.agree2 === false) {
          this.validateEula();
        }
        if (this.user.role.toLowerCase() !== "superadmin" && this.agree2 === true) {
          this.$router.push("/object-storage");
        }
        if (this.user.role.toLowerCase() === "superadmin") {
          this.$router.push("/object-storage");
        }
      }
    }

    function createErrorMessage(element) {
      return $v.value[element].$errors.length ? $v.value[element].$errors[0].$message : "";
    }

    onMounted(async () => {
      await getFlavorObjectStorage();
      await getDefaultObjectStorage();
      if (postpaid_type && postpaid_type === "fixed") {
        billingTypes.value = fixedPostpaid;
        selectedBillingType.value = "Fixed";
      } else {
        billingTypes.value = defaultBillingTypes;
        selectedBillingType.value = "PPU";
      }

      await fetchVouchers();
      // await fetchS3Flavors({ limit: 999 });
      setTimeout(() => {
        Promise.all([useComposable.fetchregions({ status: true })]);
      }, 500);

      await fetchVoucherDiscountByProjectID(projObj.id);
      if (voucherDiscountProject.value) {
        objectStorageDiscount.value = voucherDiscountProject.value.voucher_discount_detail.object_storage;
      }
    });

    const calculateCustomObjectStorage = (discount = 0, unit = "month") => {
      const size = customQuota.value ? Number(customQuota.value.slice(0, customQuota.value.length - 3)) : 0;

      let price = 0;
      if (unit == "hour") {
        price = defaultFlavorObjectStorage.value.price_per_hour;
      } else {
        price = defaultFlavorObjectStorage.value.price_per_month;
      }

      let total = 0;
      total = price * size;

      if (discount) {
        discount = (price * size * discount) / 100;
        total = total - discount;
      }

      // return toIDRWithDotFormat(Math.round(total));
      return Math.round(total);
    };

    const calculateObjectStorage = (price, discount = 0) => {
      // let price = 0
      // if (unit == "hour") {
      //   price = s3DefaultPrice.value.price_per_hours
      // } else {
      //   price = s3DefaultPrice.value.price_per_month
      // }

      let total = 0;
      total = price;

      if (discount) {
        discount = (price * discount) / 100;
        total = total - discount;
      }

      // return toIDRWithDotFormat(Math.round(total));
      return Math.round(total);
    };

    return {
      ...useComposable,
      vouchers,
      billingTypes,
      selectedRegion,
      // filteredS3Flavors,
      addGBText,
      removeGBText,
      $v,
      allowFileListing,
      objectStorageName,
      create,
      createErrorMessage,
      toIDRWithDotFormat,
      selectedTrialVoucher,
      selectedBillingType,
      objectStorageDiscount,
      calculateCustomObjectStorage,
      calculateObjectStorage,
      listFlavorObjectStorage,
      defaultFlavorObjectStorage,
      price_per_month,
      price_per_hour,
    };
  },
  async created() {
    await this.checkEula();
    const currentOrg = JSON.parse(localStorage.getItem("currentOrg"));
    const currentOrgID = currentOrg ? currentOrg.id : null;
    // this.fetchS3DefaultPrice();
  },
  data() {
    return {
      agree: false,
      agree2: true,
      tnc: false,
      dataTnc: {},
      radios: "",
    };
  },
  computed: {
    ...mapGetters({
      projectId: "PROJECT/getprojectid",
      user: "ROLEPERMISSION/getuser",
    }),
  },
  methods: {
    async validateEula() {
      var res = await api.POST("/useradmin/validate/eula", {
        form_name: "Form Create Object Storage",
        project_id: this.projectId,
      });
      if (res.code === 200) {
        this.$router.push("/object-storage");
      }
    },
    async checkEula() {
      var res = await api.GET("/useradmin/check/eula?project_id=" + this.projectId + "&form_name=Form Create Object Storage");
      if (res.code === 200) {
        this.agree2 = res.data.is_check;
        // console.log("agree : ", this.agree2);
      }
    },
    async getEula() {
      const routeData = this.$router.resolve({
        name: "eulaContent",
        query: { form_name: "Form Create Object Storage" },
        path: "/eula-content",
      });
      window.open(routeData.href, "_blank");
    },
    is(a, b) {
      return a == b;
    },
    select(instance) {
      this.instanceselected = instance;
    },
    isSelected(id) {
      return id == this.instanceselected.id;
    },
  },
  watch: {
    selectedBillingType(newVal) {
      // console.log("selectedBillingType newVal", newVal);
    },
  },
};
</script>

<style scoped lang="scss">
.select-instance,
.select-storage {
  cursor: pointer;
  &.selected {
    border-color: #2c94d2;
    .v-divider {
      border-color: #2c94d2;
    }
  }
}

.v-card.select-storage {
  p {
    margin-bottom: 0px;
    text-align: left;
  }
}
.v-card__title {
  line-height: 1.5rem;
}
.v-input--radio-group {
  ::v-deep .v-messages {
    width: 50px;
  }
  &:not(.error--text) {
    ::v-deep .v-messages {
      display: none;
    }
  }
  ::v-deep .v-input__slot {
    margin-bottom: 0;
  }
}
.higlight {
  border: 1px solid #2c94d2;
}
.bordererror {
  border: 1px solid red;
}
button {
  ::v-deep .v-btn__content {
    font-size: 14px;
    font-weight: 700;
  }
}
.v-input--is-disabled {
  ::v-deep .v-input__slot {
    background: #f1f2f2;
  }
}
</style>
