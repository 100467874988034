var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"rounded-lg px-4"},[_c('v-card-text',[_c('v-row',[_c('v-col',{staticClass:"d-flex align-center",attrs:{"cols":"7"}},[_c('div',{staticClass:"headline font-weight-bold font--text"},[_vm._v(" Manage Role ")]),_c('h3')]),(!_vm.read_only)?_c('v-col',{staticClass:"d-flex justify-center align-center",attrs:{"cols":"5"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"d-flex flex-row",attrs:{"align-self":"end"}},[_c('v-spacer'),_c('v-btn',{staticClass:"secondary",attrs:{"id":"CreateNewRoleNav","depressed":"","width":"188","height":"50","to":_vm.fullPath + '/createrole'}},[_vm._v("Add New Role")])],1)],1)],1):_vm._e()],1)],1),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"hide-default-footer":"","headers":_vm.headers,"items":_vm.items,"server-items-length":_vm.pagination.count,"options":_vm.options,"items-per-page":_vm.options.itemsPerPage},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"body",fn:function(ref){
var items = ref.items;
return [_c('tbody',_vm._l((items),function(item,ii){return _c('tr',{key:item.name + 'item'},_vm._l((_vm.headers),function(header,index){return _c('td',{key:item[header.value] + 'header' + index},[(header.value == 'action')?_c('div',{staticClass:"text-center"},[_c('a',{staticClass:"primary--text",on:{"click":function () {
                              _vm.validateprivilages([
                                'Manage Role',
                                'editor',
                                true ]).then(function () {
                                _vm.setselectedrole(item);
                                _vm.$router.push({
                                  path: _vm.fullPath + '/editrole',
                                });
                              });
                            }}},[_vm._v("Edit")]),_c('span',{staticClass:"pl-3"}),_c('a',{staticClass:"error--text",attrs:{"text":""},on:{"click":function () {
                              _vm.validateprivilages([
                                'Manage Role',
                                'editor',
                                true ]).then(function () {
                                _vm.roleselected = item;
                                _vm.opendialogdeleterole = true;
                              });
                            }}},[_vm._v("Delete")])]):_vm._e(),(header.value == 'no')?_c('div',[_vm._v(_vm._s(ii + 1))]):_c('div',[_vm._v(_vm._s(item[header.value]))])])}),0)}),0)]}},{key:"footer",fn:function(ref){
                            var props = ref.props;
return [_c('custom-footer-datatable',{attrs:{"hidden":true,"props":props},on:{"input":function (val) {
                      _vm.options = Object.assign({}, _vm.options, val);
                    }}})]}}])})],1)],1)],1)],1)],1),_c('dialogDeleteRole',{on:{"confirm":function () {
        _vm.DeleteRole();
      }},model:{value:(_vm.opendialogdeleterole),callback:function ($$v) {_vm.opendialogdeleterole=$$v},expression:"opendialogdeleterole"}}),_c('v-alert',{attrs:{"type":"success","dismissible":"","border":"left","elevation":"2"},model:{value:(_vm.deletedsuccess),callback:function ($$v) {_vm.deletedsuccess=$$v},expression:"deletedsuccess"}},[_vm._v("Role has been successfully destroyed ")]),(_vm.dialog)?_c('v-dialog',{attrs:{"max-width":"500"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline font-weight-bold font--text"},[_c('v-icon',{attrs:{"left":""}},[_vm._v("$vuetify.ison.successCircleIcon")]),_vm._v(" Data has been Deleted! ")],1),_c('v-card-text',{staticClass:"pb-0"},[_c('p',{staticClass:"mb-0"},[_vm._v("Role data has been deleted")])]),_c('v-card-actions',[_c('v-row',{staticClass:"ma-2"},[_c('v-col',[_c('v-btn',{attrs:{"height":"50","depressed":"","color":"secondary","block":""},on:{"click":function () {
                  _vm.dialog = false;
                }}},[_vm._v(" Close ")])],1)],1)],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }