var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[(_vm.loadingService || _vm.loadingDbaas)?_c('v-card',{staticClass:"rounded-lg",attrs:{"flat":""}},[_c('v-card-text',{staticClass:"d-flex justify-center"},[_c('v-progress-circular',{staticStyle:{"margin":"auto"},attrs:{"size":200,"color":"primary","indeterminate":""}})],1)],1):_c('v-card',{staticClass:"pa-8 rounded-lg",attrs:{"flat":""}},[_c('div',{staticClass:"table-title"},[_vm._v("Region")]),_c('v-col',{attrs:{"cols":"2"}},[_c('div',{staticClass:"usage-detail text-left"},[_vm._v(" "+_vm._s(_vm.project.region || "-")+" ")])]),_c('div',{staticClass:"table-title"},[_vm._v("Instance")]),_c('v-row',[_c('v-col',{staticClass:"d-flex flex-column align-center",attrs:{"cols":"2"}},[_c('div',{staticStyle:{"width":"80px"}},[(_vm.limitservices.compute.instances)?_c('pie',{attrs:{"data":{
              datasets: [
                _vm.datasets(
                  _vm.limitservices.compute.instances.used,
                  _vm.limitservices.compute.instances.limit
                ) ],

              // These labels appear in the legend and in the tooltips when hovering different arcs
              labels: ['used', 'remaining'],
            },"options":{
              legend: {
                display: false,
              },
              tooltips: {
                enabled: false,
              },
            }}}):_vm._e()],1),_c('div',{staticClass:"usage-title pt-4"},[_vm._v("Instance")]),(_vm.limitservices.compute.instances)?_c('div',{staticClass:"usage-detail"},[_vm._v(" Used "+_vm._s(_vm.limitservices.compute.instances.used)+" of "+_vm._s(_vm.limitservices.compute.instances.limit)+" ")]):_vm._e()]),_c('v-col',{staticClass:"d-flex flex-column align-center",attrs:{"cols":"2"}},[_c('div',{staticStyle:{"width":"80px"}},[(_vm.limitservices.compute.v_cpus)?_c('pie',{attrs:{"data":{
              datasets: [
                _vm.datasets(
                  _vm.limitservices.compute.v_cpus.used,
                  _vm.limitservices.compute.v_cpus.limit
                ) ],
              // These labels appear in the legend and in the tooltips when hovering different arcs
              labels: ['used', 'remaining'],
            },"options":{
              legend: {
                display: false,
              },
              tooltips: {
                enabled: false,
              },
            }}}):_vm._e()],1),_c('div',{staticClass:"usage-title pt-4"},[_vm._v("VCPUs")]),(_vm.limitservices.compute.v_cpus)?_c('div',{staticClass:"usage-detail"},[_vm._v(" Used "+_vm._s(_vm.limitservices.compute.v_cpus.used)+" of "+_vm._s(_vm.limitservices.compute.v_cpus.limit)+" ")]):_vm._e()]),_c('v-col',{staticClass:"d-flex flex-column align-center",attrs:{"cols":"2"}},[_c('div',{staticStyle:{"width":"80px"}},[(_vm.limitservices.compute.ram)?_c('pie',{attrs:{"data":{
              datasets: [
                _vm.datasets(
                  _vm.limitservices.compute.ram.used / 1024,
                  _vm.limitservices.compute.ram.limit
                ) ],

              // These labels appear in the legend and in the tooltips when hovering different arcs
              labels: ['used', 'remaining'],
            },"options":{
              legend: {
                display: false,
              },
              tooltips: {
                enabled: false,
              },
            }}}):_vm._e()],1),_c('div',{staticClass:"usage-title pt-4"},[_vm._v("RAM")]),(_vm.limitservices.compute.ram)?_c('div',{staticClass:"usage-detail"},[_vm._v(" Used "+_vm._s(_vm.formatFromMegabytes(_vm.limitservices.compute.ram.used))+" of "+_vm._s(_vm.limitservices.compute.ram.limit)+" GB ")]):_vm._e()]),_c('v-col',{staticClass:"d-flex flex-column align-center",attrs:{"cols":"2"}},[_c('div',{staticStyle:{"width":"80px"}},[(_vm.limitservices.compute.server_groups)?_c('pie',{attrs:{"data":{
              datasets: [
                _vm.datasets(
                  _vm.limitservices.compute.server_groups.used,
                  _vm.limitservices.compute.server_groups.limit
                ) ],
              // These labels appear in the legend and in the tooltips when hovering different arcs
              labels: ['used', 'remaining'],
            },"options":{
              legend: {
                display: false,
              },
              tooltips: {
                enabled: false,
              },
            }}}):_vm._e()],1),_c('div',{staticClass:"usage-title pt-4"},[_vm._v("Server Groups")]),(_vm.limitservices.compute.server_groups)?_c('div',{staticClass:"usage-detail"},[_vm._v(" Used "+_vm._s(_vm.limitservices.compute.server_groups.used)+" of "+_vm._s(_vm.limitservices.compute.server_groups.limit)+" ")]):_vm._e()]),_c('v-col',{staticClass:"d-flex flex-column align-center",attrs:{"cols":"2"}},[_c('div',{staticStyle:{"width":"80px"}},[(_vm.limitservices.compute.server_group_members)?_c('pie',{attrs:{"data":{
              datasets: [
                _vm.datasets(
                  _vm.limitservices.compute.server_group_members.used,
                  _vm.limitservices.compute.server_group_members.limit
                ) ],
              // These labels appear in the legend and in the tooltips when hovering different arcs
              labels: ['used', 'remaining'],
            },"options":{
              legend: {
                display: false,
              },
              tooltips: {
                enabled: false,
              },
            }}}):_vm._e()],1),_c('div',{staticClass:"usage-title pt-4",staticStyle:{"width":"280px"}},[_vm._v("Server Group Members")]),(_vm.limitservices.compute.server_group_members)?_c('div',{staticClass:"usage-detail"},[_vm._v(" Used "+_vm._s(_vm.limitservices.compute.server_group_members.used)+" of "+_vm._s(_vm.limitservices.compute.server_group_members.limit)+" ")]):_vm._e()])],1),_c('div',{staticClass:"table-title"},[_vm._v("Storage")]),_c('v-row',[_c('v-col',{staticClass:"d-flex flex-column align-center",attrs:{"cols":"2"}},[_c('div',{staticStyle:{"width":"80px"}},[(_vm.limitservices.volumes.volumes)?_c('pie',{attrs:{"data":{
              datasets: [
                _vm.datasets(
                  _vm.limitservices.volumes.volumes.used,
                  _vm.limitservices.volumes.volumes.limit
                ) ],
              // These labels appear in the legend and in the tooltips when hovering different arcs
              labels: ['used', 'remaining'],
            },"options":{
              legend: {
                display: false,
              },
              tooltips: {
                enabled: false,
              },
            }}}):_vm._e()],1),_c('div',{staticClass:"usage-title pt-4"},[_vm._v("Volumes")]),(_vm.limitservices.volumes.volumes)?_c('div',{staticClass:"usage-detail"},[_vm._v(" Used "+_vm._s(_vm.limitservices.volumes.volumes.used)+" of "+_vm._s(_vm.limitservices.volumes.volumes.limit)+" ")]):_vm._e()]),_c('v-col',{staticClass:"d-flex flex-column align-center",attrs:{"cols":"2"}},[_c('div',{staticStyle:{"width":"80px"}},[(_vm.limitservices.volumes.volume_snapshots)?_c('pie',{attrs:{"data":{
              datasets: [
                _vm.datasets(
                  _vm.limitservices.volumes.volume_snapshots.used,
                  _vm.limitservices.volumes.volume_snapshots.limit
                ) ],

              // These labels appear in the legend and in the tooltips when hovering different arcs
              labels: ['used', 'remaining'],
            },"options":{
              legend: {
                display: false,
              },
              tooltips: {
                enabled: false,
              },
            }}}):_vm._e()],1),_c('div',{staticClass:"usage-title pt-4"},[_vm._v("Volumes Snapshots")]),(_vm.limitservices.volumes.volume_snapshots)?_c('div',{staticClass:"usage-detail"},[_vm._v(" Used "+_vm._s(_vm.limitservices.volumes.volume_snapshots.used)+" of "+_vm._s(_vm.limitservices.volumes.volume_snapshots.limit)+" ")]):_vm._e()]),_c('v-col',{staticClass:"d-flex flex-column align-center",attrs:{"cols":"2"}},[_c('div',{staticStyle:{"width":"80px"}},[(_vm.limitservices.volumes.volume_storage)?_c('pie',{attrs:{"data":{
              datasets: [
                _vm.datasets(
                  _vm.limitservices.volumes.volume_storage.used,
                  _vm.limitservices.volumes.volume_storage.limit
                ) ],
              // These labels appear in the legend and in the tooltips when hovering different arcs
              labels: ['used', 'remaining'],
            },"options":{
              legend: {
                display: false,
              },
              tooltips: {
                enabled: false,
              },
            }}}):_vm._e()],1),_c('div',{staticClass:"usage-title pt-4"},[_vm._v("Volume Storages")]),(_vm.limitservices.volumes.volume_storage)?_c('div',{staticClass:"usage-detail"},[_vm._v(" Used "+_vm._s(_vm.limitservices.volumes.volume_storage.used ? _vm.formatCalculateVolumeStorage(_vm.limitservices.volumes.volume_storage.used) : "0 Bytes")+" of "+_vm._s(_vm.limitservices.volumes.volume_storage.limit == -1 ? "unlimited" : _vm.formatCalculateVolumeStorage(_vm.limitservices.volumes.volume_storage.limit))+" ")]):_vm._e()])],1),_c('div',{staticClass:"table-title"},[_vm._v("Object Storage")]),_c('v-row',[_c('v-col',{staticClass:"d-flex flex-column align-center",attrs:{"cols":"2"}},[_c('div',{staticStyle:{"width":"80px"}},[(_vm.limitservices.object_storage.object_storage_total)?_c('pie',{attrs:{"data":{
              datasets: [
                _vm.datasets(
                  _vm.limitservices.object_storage.object_storage_total.used,
                  _vm.limitservices.object_storage.object_storage_total.limit
                ) ],
              // These labels appear in the legend and in the tooltips when hovering different arcs
              labels: ['used', 'remaining'],
            },"options":{
              legend: {
                display: false,
              },
              tooltips: {
                enabled: false,
              },
            }}}):_vm._e()],1),_c('div',{staticClass:"usage-title pt-4"},[_vm._v("Object Storage")]),(_vm.limitservices.object_storage.object_storage_total)?_c('div',{staticClass:"usage-detail"},[_vm._v(" Used "+_vm._s(_vm.limitservices.object_storage.object_storage_total.used)+" of "+_vm._s(_vm.limitservices.object_storage.object_storage_total.limit == -1 ? "unlimited" : _vm.limitservices.object_storage.object_storage_total.limit)+" ")]):_vm._e()]),_c('v-col',{staticClass:"d-flex flex-column align-center",attrs:{"cols":"2"}},[_c('div',{staticStyle:{"width":"80px"}},[(_vm.limitservices.object_storage.object_storage)?_c('pie',{attrs:{"data":{
              datasets: [
                _vm.datasets(
                  _vm.limitservices.object_storage.object_storage.used,
                  _vm.limitservices.object_storage.object_storage.limit
                ) ],

              // These labels appear in the legend and in the tooltips when hovering different arcs
              labels: ['used', 'remaining'],
            },"options":{
              legend: {
                display: false,
              },
              tooltips: {
                enabled: false,
              },
            }}}):_vm._e()],1),_c('div',{staticClass:"usage-title pt-4"},[_vm._v("Object Storage Size")]),(_vm.limitservices.object_storage.object_storage)?_c('div',{staticClass:"usage-detail"},[_vm._v(" Used "+_vm._s(_vm.limitservices.object_storage.object_storage.used ? ((_vm.limitservices.object_storage.object_storage.used) + " GB") : "0 Bytes")+" of "+_vm._s(_vm.limitservices.object_storage.object_storage.limit == -1 ? "unlimited" : _vm.limitservices.object_storage.object_storage.limit)+" GB ")]):_vm._e()])],1),_c('div',{staticClass:"table-title"},[_vm._v("Network")]),_c('v-row',[_c('v-col',{staticClass:"d-flex flex-column align-center",attrs:{"cols":"2"}},[_c('div',{staticStyle:{"width":"80px"}},[(_vm.limitservices.network.floating_ips)?_c('pie',{attrs:{"data":{
              datasets: [
                _vm.datasets(
                  _vm.limitservices.network.floating_ips.used,
                  _vm.limitservices.network.floating_ips.limit
                ) ],
              // These labels appear in the legend and in the tooltips when hovering different arcs
              labels: ['used', 'remaining'],
            },"options":{
              legend: {
                display: false,
              },
              tooltips: {
                enabled: false,
              },
            }}}):_vm._e()],1),_c('div',{staticClass:"usage-title pt-4"},[_vm._v("Floating IPs")]),(_vm.limitservices.network.floating_ips)?_c('div',{staticClass:"usage-detail"},[_vm._v(" Allocated "+_vm._s(_vm.limitservices.network.floating_ips.used)+" of "+_vm._s(_vm.limitservices.network.floating_ips.limit)+" ")]):_vm._e()]),_c('v-col',{staticClass:"d-flex flex-column align-center",attrs:{"cols":"2"}},[_c('div',{staticStyle:{"width":"80px"}},[(_vm.limitservices.network.security_groups)?_c('pie',{attrs:{"data":{
              datasets: [
                _vm.datasets(
                  _vm.limitservices.network.security_groups.used,
                  _vm.limitservices.network.security_groups.limit
                ) ],
              // These labels appear in the legend and in the tooltips when hovering different arcs
              labels: ['used', 'remaining'],
            },"options":{
              legend: {
                display: false,
              },
              tooltips: {
                enabled: false,
              },
            }}}):_vm._e()],1),_c('div',{staticClass:"usage-title pt-4"},[_vm._v("Security Groups")]),(_vm.limitservices.network.security_groups)?_c('div',{staticClass:"usage-detail"},[_vm._v(" Used "+_vm._s(_vm.limitservices.network.security_groups.used)+" of "+_vm._s(_vm.limitservices.network.security_groups.limit)+" ")]):_vm._e()]),_c('v-col',{staticClass:"d-flex flex-column align-center",attrs:{"cols":"2"}},[_c('div',{staticStyle:{"width":"80px"}},[(_vm.limitservices.network.security_group_rules)?_c('pie',{attrs:{"data":{
              datasets: [
                _vm.datasets(
                  _vm.limitservices.network.security_group_rules.used,
                  _vm.limitservices.network.security_group_rules.limit
                ) ],
              // These labels appear in the legend and in the tooltips when hovering different arcs
              labels: ['used', 'remaining'],
            },"options":{
              legend: {
                display: false,
              },
              tooltips: {
                enabled: false,
              },
            }}}):_vm._e()],1),_c('div',{staticClass:"usage-title pt-4"},[_vm._v("Security Group Rules")]),(_vm.limitservices.network.security_group_rules)?_c('div',{staticClass:"usage-detail"},[_vm._v(" Used "+_vm._s(_vm.limitservices.network.security_group_rules.used)+" of "+_vm._s(_vm.limitservices.network.security_group_rules.limit)+" ")]):_vm._e()]),_c('v-col',{staticClass:"d-flex flex-column align-center",attrs:{"cols":"2"}},[_c('div',{staticStyle:{"width":"80px"}},[(_vm.limitservices.network.networks)?_c('pie',{attrs:{"data":{
              datasets: [
                _vm.datasets(
                  _vm.limitservices.network.networks.used,
                  _vm.limitservices.network.networks.limit
                ) ],

              // These labels appear in the legend and in the tooltips when hovering different arcs
              labels: ['used', 'remaining'],
            },"options":{
              legend: {
                display: false,
              },
              tooltips: {
                enabled: false,
              },
            }}}):_vm._e()],1),_c('div',{staticClass:"usage-title pt-4"},[_vm._v("Networks")]),(_vm.limitservices.network.networks)?_c('div',{staticClass:"usage-detail"},[_vm._v(" Used "+_vm._s(_vm.limitservices.network.networks.used)+" of "+_vm._s(_vm.limitservices.network.networks.limit)+" ")]):_vm._e()]),_c('v-col',{staticClass:"d-flex flex-column align-center",attrs:{"cols":"2"}},[_c('div',{staticStyle:{"width":"80px"}},[(_vm.limitservices.network.ports)?_c('pie',{attrs:{"data":{
              datasets: [
                _vm.datasets(
                  _vm.limitservices.network.ports.used,
                  _vm.limitservices.network.ports.limit
                ) ],
              // These labels appear in the legend and in the tooltips when hovering different arcs
              labels: ['used', 'remaining'],
            },"options":{
              legend: {
                display: false,
              },
              tooltips: {
                enabled: false,
              },
            }}}):_vm._e()],1),_c('div',{staticClass:"usage-title pt-4"},[_vm._v("Ports")]),(_vm.limitservices.network.ports)?_c('div',{staticClass:"usage-detail"},[_vm._v(" Used "+_vm._s(_vm.limitservices.network.ports.used)+" of "+_vm._s(_vm.limitservices.network.ports.limit)+" ")]):_vm._e()]),_c('v-col',{staticClass:"d-flex flex-column align-center",attrs:{"cols":"2"}},[_c('div',{staticStyle:{"width":"80px"}},[(_vm.limitservices.network.routers)?_c('pie',{attrs:{"data":{
              datasets: [
                _vm.datasets(
                  _vm.limitservices.network.routers.used,
                  _vm.limitservices.network.routers.limit
                ) ],
              // These labels appear in the legend and in the tooltips when hovering different arcs
              labels: ['used', 'remaining'],
            },"options":{
              legend: {
                display: false,
              },
              tooltips: {
                enabled: false,
              },
            }}}):_vm._e()],1),_c('div',{staticClass:"usage-title pt-4"},[_vm._v("Routers")]),(_vm.limitservices.network.routers)?_c('div',{staticClass:"usage-detail"},[_vm._v(" Used "+_vm._s(_vm.limitservices.network.routers.used)+" of "+_vm._s(_vm.limitservices.network.routers.limit)+" ")]):_vm._e()])],1),_c('div',{staticClass:"table-title"},[_vm._v("DBaaS")]),_c('v-row',[_c('v-col',{staticClass:"d-flex flex-column align-center",attrs:{"cols":"2"}},[_c('div',{staticStyle:{"width":"80px"}},[(_vm.dbaasLimit)?_c('pie',{attrs:{"data":{
              datasets: [
                _vm.datasets(
                  _vm.dbaasLimit.used,
                  _vm.dbaasLimit.limit
                ) ],
              // These labels appear in the legend and in the tooltips when hovering different arcs
              labels: ['used', 'remaining'],
            },"options":{
              legend: {
                display: false,
              },
              tooltips: {
                enabled: false,
              },
            }}}):_vm._e()],1),_c('div',{staticClass:"usage-title pt-4"},[_vm._v("Datastore")]),(_vm.dbaasLimit)?_c('div',{staticClass:"usage-detail"},[_vm._v(" Used "+_vm._s(_vm.dbaasLimit.used)+" of "+_vm._s(_vm.dbaasLimit.limit)+" ")]):_vm._e()])],1),_c('div',{staticClass:"table-title"},[_vm._v("Paid OS")]),_c('v-row',[_c('v-col',{staticClass:"d-flex flex-column align-center",attrs:{"cols":"2"}},[_c('div',{staticStyle:{"width":"80px"}},[(_vm.limitservices.paid_os.paid_os)?_c('pie',{attrs:{"data":{
              datasets: [
                _vm.datasets(
                  _vm.limitservices.paid_os.paid_os.used,
                  _vm.limitservices.paid_os.paid_os.limit
                ) ],

              // These labels appear in the legend and in the tooltips when hovering different arcs
              labels: ['used', 'remaining'],
            },"options":{
              legend: {
                display: false,
              },
              tooltips: {
                enabled: false,
              },
            }}}):_vm._e()],1),_c('div',{staticClass:"usage-title pt-4"},[_vm._v("Paid OS")]),(_vm.limitservices.paid_os.paid_os)?_c('div',{staticClass:"usage-detail"},[_vm._v(" Used "+_vm._s(_vm.limitservices.paid_os.paid_os.used)+" of "+_vm._s(_vm.limitservices.paid_os.paid_os.limit)+" ")]):_vm._e()])],1),_c('div',{staticClass:"table-title"},[_vm._v("CDN")]),_c('v-row',[_c('v-col',{staticClass:"d-flex flex-column align-center",attrs:{"cols":"2"}},[_c('div',{staticStyle:{"width":"80px"}},[(_vm.cdnLimit)?_c('pie',{attrs:{"data":{
              datasets: [
                _vm.datasets(
                  _vm.cdnLimit.used,
                  _vm.cdnLimit.limit
                ) ],

              // These labels appear in the legend and in the tooltips when hovering different arcs
              labels: ['used', 'remaining'],
            },"options":{
              legend: {
                display: false,
              },
              tooltips: {
                enabled: false,
              },
            }}}):_vm._e()],1),_c('div',{staticClass:"usage-title pt-4"},[_vm._v("CDN")]),(_vm.cdnLimit)?_c('div',{staticClass:"usage-detail"},[_vm._v(" Used "+_vm._s(_vm.cdnLimit.used)+" of "+_vm._s(_vm.cdnLimit.limit)+" ")]):_vm._e()])],1),_c('div',{staticClass:"table-title"},[_vm._v("Backup Scheduler")]),_c('v-row',[_c('v-col',{staticClass:"d-flex flex-column align-center",attrs:{"cols":"2"}},[_c('div',{staticStyle:{"width":"80px"}},[(_vm.limitservices.backup_scheduler.backup_scheduler)?_c('pie',{attrs:{"data":{
              datasets: [
                _vm.datasets(
                  _vm.limitservices.backup_scheduler.backup_scheduler.used,
                  _vm.limitservices.backup_scheduler.backup_scheduler.limit
                ) ],

              // These labels appear in the legend and in the tooltips when hovering different arcs
              labels: ['used', 'remaining'],
            },"options":{
              legend: {
                display: false,
              },
              tooltips: {
                enabled: false,
              },
            }}}):_vm._e()],1),_c('div',{staticClass:"usage-title pt-4"},[_vm._v("Backup Scheduler")]),(_vm.limitservices.backup_scheduler.backup_scheduler)?_c('div',{staticClass:"usage-detail"},[_vm._v(" Used "+_vm._s(_vm.limitservices.backup_scheduler.backup_scheduler.used)+" of "+_vm._s(_vm.limitservices.backup_scheduler.backup_scheduler.limit)+" ")]):_vm._e()])],1),_c('div',{staticClass:"table-title"},[_vm._v("Registry Limit")]),_c('v-row',[_c('v-col',{staticClass:"d-flex flex-column align-center",attrs:{"cols":"2"}},[_c('div',{staticStyle:{"width":"80px"}},[(_vm.detailquotastatus<299 && _vm.detailquotastatus!=204)?_c('pie',{attrs:{"data":{
              datasets: [
                _vm.datasets(
                  _vm.detailquotalimits.Limit_registry.used,
                  _vm.detailquotalimits.Limit_registry.hard
                ) ],

              // These labels appear in the legend and in the tooltips when hovering different arcs
              labels: ['used', 'remaining'],
            },"options":{
              legend: {
                display: false,
              },
              tooltips: {
                enabled: false,
              },
            }}}):_vm._e()],1),_c('div',{staticClass:"usage-title pt-4"},[_vm._v("Registry")]),(_vm.detailquotastatus<299 && _vm.detailquotastatus!=204)?_c('div',{staticClass:"usage-detail"},[_vm._v(" Used "+_vm._s(_vm.detailquotalimits.Limit_registry.used)+" of "+_vm._s(_vm.detailquotalimits.Limit_registry.hard)+" ")]):_c('div',{staticClass:"usage-detail"},[_vm._v(" Used 0 of 0 ")])]),_c('v-col',{staticClass:"d-flex flex-column align-center",attrs:{"cols":"2"}},[_c('div',{staticStyle:{"width":"80px"}},[(_vm.detailquotastatus<299 && _vm.detailquotastatus!=204)?_c('pie',{attrs:{"data":{
              datasets: [
                _vm.datasets(
                  _vm.detailquotalimits.Limit_storage.used,
                  _vm.detailquotalimits.Limit_storage.limit
                ) ],

              // These labels appear in the legend and in the tooltips when hovering different arcs
              labels: ['used', 'remaining'],
            },"options":{
              legend: {
                display: false,
              },
              tooltips: {
                enabled: false,
              },
            }}}):_vm._e()],1),_c('div',{staticClass:"usage-title pt-4"},[_vm._v("Storage")]),(_vm.detailquotastatus<299 && _vm.detailquotastatus!=204)?_c('div',{staticClass:"usage-detail"},[_vm._v(" Used "+_vm._s(_vm.$formatSize(_vm.detailquotalimits.Limit_storage.used))+" of "+_vm._s(_vm.$formatSize(_vm.detailquotalimits.Limit_storage.limit))+" ")]):_c('div',{staticClass:"usage-detail"},[_vm._v(" Used 0 of 0 ")])])],1),_c('div',{staticClass:"table-title"},[_vm._v("Dekarock")]),(!_vm.loadingDekarock)?_c('v-row',[_c('v-col',{staticClass:"d-flex flex-column align-center",attrs:{"cols":"2"}},[_c('div',{staticStyle:{"width":"80px"}},[_c('pie',{attrs:{"data":{
              datasets: [_vm.datasets(_vm.dekarockSettings.limit.cluster_used,_vm.dekarockSettings.limit.cluster)],
              labels: ['used', 'remaining'],
            },"options":{
              legend: {display: false},
              tooltips: {enabled: false},
            }}})],1),_c('div',{staticClass:"usage-title pt-4"},[_vm._v("Cluster")]),_c('div',{staticClass:"usage-detail"},[_vm._v(" Used "+_vm._s(_vm.dekarockSettings.limit.cluster_used)+" of "+_vm._s(_vm.dekarockSettings.limit.cluster)+" ")])]),_c('v-col',{staticClass:"d-flex flex-column align-center",attrs:{"cols":"2"}},[_c('div',{staticStyle:{"width":"80px"}},[_c('pie',{attrs:{"data":{
              datasets: [_vm.datasets(_vm.dekarockSettings.limit.machine_set_used,_vm.dekarockSettings.limit.machine_set)],
              labels: ['used', 'remaining'],
            },"options":{
              legend: {display: false},
              tooltips: {enabled: false},
            }}})],1),_c('div',{staticClass:"usage-title pt-4"},[_vm._v("Machine Set")]),_c('div',{staticClass:"usage-detail"},[_vm._v(" Used "+_vm._s(_vm.dekarockSettings.limit.machine_set_used)+" of "+_vm._s(_vm.dekarockSettings.limit.machine_set)+" ")])]),_c('v-col',{staticClass:"d-flex flex-column align-center",attrs:{"cols":"2"}},[_c('div',{staticStyle:{"width":"80px"}},[_c('pie',{attrs:{"data":{
              datasets: [_vm.datasets(_vm.dekarockSettings.limit.worker_used,_vm.dekarockSettings.limit.worker)],
              labels: ['used', 'remaining'],
            },"options":{
              legend: {display: false},
              tooltips: {enabled: false},
            }}})],1),_c('div',{staticClass:"usage-title pt-4"},[_vm._v("Worker")]),_c('div',{staticClass:"usage-detail"},[_vm._v(" Used "+_vm._s(_vm.dekarockSettings.limit.worker_used)+" of "+_vm._s(_vm.dekarockSettings.limit.worker)+" ")])])],1):_c('v-row',[_c('v-col',{staticClass:"d-flex flex-column align-center",attrs:{"cols":"6"}},[_vm._v("Get Dekarock Limit still Loading . . .")])],1),_c('div',{staticClass:"table-title"},[_vm._v("Deka Harbor")]),(!_vm.loadingDekaharbor)?_c('v-row',[_c('v-col',{staticClass:"d-flex flex-column align-center",attrs:{"cols":"2"}},[_c('div',{staticStyle:{"width":"80px"}},[_c('pie',{attrs:{"data":{
              datasets: [_vm.datasets(_vm.limitHarbor.used_master,_vm.limitHarbor.master)],
              labels: ['used', 'remaining'],
            },"options":{
              legend: {display: false},
              tooltips: {enabled: false},
            }}})],1),_c('div',{staticClass:"usage-title pt-4"},[_vm._v("Cluster")]),_c('div',{staticClass:"usage-detail"},[_vm._v(" Used "+_vm._s(_vm.limitHarbor.used_master)+" of "+_vm._s(_vm.limitHarbor.master)+" ")])]),_c('v-col',{staticClass:"d-flex flex-column align-center",attrs:{"cols":"2"}},[_c('div',{staticStyle:{"width":"80px"}},[_c('pie',{attrs:{"data":{
              datasets: [_vm.datasets(_vm.limitHarbor.used_worker,_vm.limitHarbor.worker)],
              labels: ['used', 'remaining'],
            },"options":{
              legend: {display: false},
              tooltips: {enabled: false},
            }}})],1),_c('div',{staticClass:"usage-title pt-4"},[_vm._v("Worker")]),_c('div',{staticClass:"usage-detail"},[_vm._v(" Used "+_vm._s(_vm.limitHarbor.used_worker)+" of "+_vm._s(_vm.limitHarbor.worker)+" ")])]),_c('v-col',{staticClass:"d-flex flex-column align-center",attrs:{"cols":"2"}},[_c('div',{staticStyle:{"width":"80px"}},[_c('pie',{attrs:{"data":{
              datasets: [_vm.datasets(_vm.limitHarbor.used_load_balancer,_vm.limitHarbor.load_balancer)],
              labels: ['used', 'remaining'],
            },"options":{
              legend: {display: false},
              tooltips: {enabled: false},
            }}})],1),_c('div',{staticClass:"usage-title pt-4"},[_vm._v("Load Balancer")]),_c('div',{staticClass:"usage-detail"},[_vm._v(" Used "+_vm._s(_vm.limitHarbor.used_load_balancer)+" of "+_vm._s(_vm.limitHarbor.load_balancer)+" ")])])],1):_c('v-row',[_c('v-col',{staticClass:"d-flex flex-column align-center",attrs:{"cols":"6"}},[_vm._v("Get Deka Harbor Limit still Loading . . .")])],1),_c('div',{staticClass:"table-title"},[_vm._v("NAT Gateway")]),_c('v-row',[_c('v-col',{staticClass:"d-flex flex-column align-center",attrs:{"cols":"2"}},[_c('div',{staticStyle:{"width":"80px"}},[(_vm.getLimitRouter)?_c('pie',{attrs:{"data":{
              datasets: [
                _vm.datasets(
                  _vm.getLimitRouter.router,
                  _vm.getLimitRouter.limit
                ) ],

              // These labels appear in the legend and in the tooltips when hovering different arcs
              labels: ['used', 'remaining'],
            },"options":{
              legend: {
                display: false,
              },
              tooltips: {
                enabled: false,
              },
            }}}):_vm._e()],1),_c('div',{staticClass:"usage-title pt-4"},[_vm._v("NAT Gateway")]),(_vm.getLimitRouter)?_c('div',{staticClass:"usage-detail"},[_vm._v(" Used "+_vm._s(_vm.getLimitRouter.router)+" of "+_vm._s(_vm.getLimitRouter.limit)+" ")]):_vm._e()])],1),_c('div',{staticClass:"table-title"},[_vm._v("VPN")]),_c('v-row',[_c('v-col',{staticClass:"d-flex flex-column align-center",attrs:{"cols":"2"}},[_c('div',{staticStyle:{"width":"80px"}},[(_vm.getLimitVpn)?_c('pie',{attrs:{"data":{
              datasets: [
                _vm.datasets(
                  _vm.getLimitVpn.vpn,
                  _vm.getLimitVpn.limit
                ) ],

              // These labels appear in the legend and in the tooltips when hovering different arcs
              labels: ['used', 'remaining'],
            },"options":{
              legend: {
                display: false,
              },
              tooltips: {
                enabled: false,
              },
            }}}):_vm._e()],1),_c('div',{staticClass:"usage-title pt-4"},[_vm._v("VPN")]),(_vm.getLimitVpn)?_c('div',{staticClass:"usage-detail"},[_vm._v(" Used "+_vm._s(_vm.getLimitVpn.vpn)+" of "+_vm._s(_vm.getLimitVpn.limit)+" ")]):_vm._e()])],1),_c('div',{staticClass:"table-title"},[_vm._v("Load Balancer")]),_c('v-row',[_c('v-col',{staticClass:"d-flex flex-column align-center",attrs:{"cols":"2"}},[_c('div',{staticStyle:{"width":"80px"}},[(_vm.limitSlb)?_c('pie',{attrs:{"data":{
              datasets: [
                _vm.datasets(
                  _vm.limitSlb.used_load_balancer,
                  _vm.limitSlb.load_balancer
                ) ],

              // These labels appear in the legend and in the tooltips when hovering different arcs
              labels: ['used', 'remaining'],
            },"options":{
              legend: {
                display: false,
              },
              tooltips: {
                enabled: false,
              },
            }}}):_vm._e()],1),_c('div',{staticClass:"usage-title pt-4"},[_vm._v("Server Load Balancer")]),(_vm.limitSlb)?_c('div',{staticClass:"usage-detail"},[_vm._v(" Used "+_vm._s(_vm.limitSlb.used_load_balancer)+" of "+_vm._s(_vm.limitSlb.load_balancer)+" ")]):_vm._e()]),_c('v-col',{staticClass:"d-flex flex-column align-center",attrs:{"cols":"2"}},[_c('div',{staticStyle:{"width":"80px"}},[(_vm.limitGlb)?_c('pie',{attrs:{"data":{
              datasets: [
                _vm.datasets(
                  _vm.limitGlb.used_load_balancer,
                  _vm.limitGlb.load_balancer
                ) ],

              // These labels appear in the legend and in the tooltips when hovering different arcs
              labels: ['used', 'remaining'],
            },"options":{
              legend: {
                display: false,
              },
              tooltips: {
                enabled: false,
              },
            }}}):_vm._e()],1),_c('div',{staticClass:"usage-title pt-4"},[_vm._v("Global Load Balancer")]),(_vm.limitGlb)?_c('div',{staticClass:"usage-detail"},[_vm._v(" Used "+_vm._s(_vm.limitGlb.used_load_balancer)+" of "+_vm._s(_vm.limitGlb.load_balancer)+" ")]):_vm._e()])],1),_c('div',{staticClass:"table-title"},[_vm._v("Deka AST")]),(!_vm.loadingDekaAst)?_c('v-row',[_c('v-col',{staticClass:"d-flex flex-column align-center",attrs:{"cols":"2"}},[_c('div',{staticStyle:{"width":"80px"}},[_c('pie',{attrs:{"data":{
              datasets: [_vm.datasets(_vm.getServiceListAst.used_project,_vm.getServiceListAst.limit_project)],
              labels: ['used', 'remaining'],
            },"options":{
              legend: {display: false},
              tooltips: {enabled: false},
            }}})],1),_c('div',{staticClass:"usage-title pt-4"},[_vm._v("Limit Project")]),_c('div',{staticClass:"usage-detail"},[_vm._v(" Used "+_vm._s(_vm.getServiceListAst.used_project)+" of "+_vm._s(_vm.getServiceListAst.limit_project)+" ")])]),_c('v-col',{staticClass:"d-flex flex-column align-center",attrs:{"cols":"2"}},[_c('div',{staticStyle:{"width":"80px"}},[_c('pie',{attrs:{"data":{
              datasets: [_vm.datasets(_vm.getServiceListAst.used_site,_vm.getServiceListAst.limit_site)],
              labels: ['used', 'remaining'],
            },"options":{
              legend: {display: false},
              tooltips: {enabled: false},
            }}})],1),_c('div',{staticClass:"usage-title pt-4"},[_vm._v("Limit Site")]),_c('div',{staticClass:"usage-detail"},[_vm._v(" Used "+_vm._s(_vm.getServiceListAst.used_site)+" of "+_vm._s(_vm.getServiceListAst.limit_site)+" ")])])],1):_c('v-row',[_c('v-col',{staticClass:"d-flex flex-column align-center",attrs:{"cols":"6"}},[_vm._v("Get Deka AST Limit still Loading . . .")])],1),_c('div',{staticClass:"table-title"},[_vm._v("Deka Agent Premium")]),_c('v-row',[_c('v-col',{staticClass:"d-flex flex-column align-center",attrs:{"cols":"2"}},[_c('div',{staticStyle:{"width":"80px"}},[(_vm.limitservices.deka_agent.deka_agent_total)?_c('pie',{attrs:{"data":{
              datasets: [
                _vm.datasets(
                  _vm.limitservices.deka_agent.deka_agent_total.used,
                  _vm.limitservices.deka_agent.deka_agent_total.limit
                ) ],

              // These labels appear in the legend and in the tooltips when hovering different arcs
              labels: ['used', 'remaining'],
            },"options":{
              legend: {
                display: false,
              },
              tooltips: {
                enabled: false,
              },
            }}}):_vm._e()],1),_c('div',{staticClass:"usage-title pt-4"},[_vm._v("Deka Agent Premium")]),(_vm.limitservices.deka_agent.deka_agent_total)?_c('div',{staticClass:"usage-detail"},[_vm._v(" Used "+_vm._s(_vm.limitservices.deka_agent.deka_agent_total.used)+" of "+_vm._s(_vm.limitservices.deka_agent.deka_agent_total.limit)+" ")]):_vm._e()])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }