import { Module } from "vuex";
import axios from "axios";

function makeHeaders() {
  const params = localStorage.getItem("token");
  return {
    headers: { Authorization: `Bearer ${params}` },
  };
}

interface state {
  listFlavorObjectStorage: Array<any>;
  defaultFlavorObjectStorage: Object;
}

const store: Module<state, any> = {
  namespaced: true,
  state: {
    listFlavorObjectStorage: [],
    defaultFlavorObjectStorage: {},
  },
  getters: {
    listFlavorObjectStorage: (state) => {
      return state.listFlavorObjectStorage;
    },
    defaultFlavorObjectStorage: (state) => {
      return state.defaultFlavorObjectStorage;
    },
  },
  mutations: {
    setListFlavorObjectStorage: (state: state, payload) => {
      state.listFlavorObjectStorage = payload;
    },
    setDefaultFlavorObjectStorage: (state: state, payload) => {
      state.defaultFlavorObjectStorage = payload;
    },
  },
  actions: {
    getFlavorObjectStorage: async ({ commit }) => {
      try {
        const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL_BILLING}/flavor/object-storage`, makeHeaders());
        commit("setListFlavorObjectStorage", response.data.data);
      } catch (error) {
        // dispatch("HOMEPAGE/showErrorToast", `${error.response.data.responseMessage}`, { root: true });
      }
    },
    getDefaultObjectStorage: async ({ commit }) => {
      try {
        const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL_BILLING}/flavor/object-storage/default`, makeHeaders());
        commit("setDefaultFlavorObjectStorage", response.data.data);
      } catch (error) {
        // dispatch("HOMEPAGE/showErrorToast", `${error.response.data.responseMessage}`, { root: true });
      }
    },
  },
};

export default store;
