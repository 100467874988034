<template>
  <v-card-text>
    <v-container>
      <v-col class="justify-center">
        <h1
          style="
            text-align: center;
            font-weight: 800;
            font-size: 28px;
            line-height: 36px;
            letter-spacing: 0.04em;
            font-family: Mont;
            font-style: normal;
          "
        >
          1. CREATE ORGANIZATION
        </h1>
        <p
          style="font-size: 20px; color: #a5b3bf; line-height: 27.24px"
          class="text-center mt-5"
        >
          You have to create an organization before using the app
        </p>
        <v-form ref="form" style="margin-top: 40px" lazy-validation>
          <v-alert v-if="errorMessage" color="red" type="error"
            >{{ errorMessage }}
          </v-alert>

          <v-text-field
            id="name"
            flat
            outlined
            maxlength="30"
            v-model="name"
            placeholder="Name of Organization"
            required
            style="margin-bottom: 22px"
            :error-messages="
              nameValidation.$errors.length
                ? nameValidation.$errors[0].$message
                : ''
            "
          ></v-text-field>

          <v-textarea
            rows="5"
            id="details"
            flat
            outlined
            maxlength="255"
            v-model="details"
            placeholder="Details"
            required
            style="margin-bottom: 22px"
            :error-messages="
              detailsValidation.$errors.length
                ? detailsValidation.$errors[0].$message
                : ''
            "
          ></v-textarea>

          <v-textarea
            rows="5"
            id="description"
            flat
            outlined
            maxlength="255"
            v-model="address"
            placeholder="Address"
            required
            style="margin-bottom: 22px"
            :error-messages="
              addressValidation.$errors.length
                ? addressValidation.$errors[0].$message
                : ''
            "
          ></v-textarea>
          <v-text-field
            v-mask="[/[1-9]/, /[0-9]/, /[0-9]/, ' ' ,/[0-9]/, /[0-9]/, /[0-9]/, ' ' ,/[0-9]/, /[0-9]/, /[0-9]/,' ' ,/[0-9]/, /[0-9]/, /[0-9]/]"
            flat
            outlined
            v-model="phone"
            placeholder="Phone Number"
            required
            prefix="+62"
            style="margin-bottom: 22px"
            :error-messages="
              phoneValidation.$errors.length
                ? phoneValidation.$errors[0].$message
                : ''
            "
          ></v-text-field>

          <v-autocomplete
            v-model="sales_id"
            outlined
            :items="saleses"
            error-messages=""
            placeholder="Select Sales"
            style="margin-bottom: 22px"
            item-text="fullname"
            item-value="id"
          />

          <v-btn
            :disabled="isLoading ? true : false"
            id="submit"
            block
            height="50"
            depressed
            color="secondary"
            class="mr-4 mt-7 text-capitalize font-weight-bold"
            @click="save"
          >
            {{ isLoading ? "Please wait..." : "Create" }}
          </v-btn>
          <br />
        </v-form>
      </v-col>
    </v-container>
  </v-card-text>
</template>
<script>
import { useAction } from "./useAction";
import { mapGetters } from "vuex";
import localstorage from "@/lib/localstorage";
import api from '@/lib/api';

export default {
  computed: {
    ...mapGetters({
      loggedUser: "CREATEORGANIZATION/getProfile",
      isLoading: "CREATEORGANIZATION/isLoading",
    }),
  },
  components: {},
  data() {
    return {
      errorMessage: undefined,
      success: false,
      saleses: []
    };
  },
  async mounted() {
    await this.Profile();
    if (!this.loggedUser) this.$route.push("/");
    this.getSaleses();
  },
  methods: {
    closeDialog() {
      this.$router.push("/");
    },
    async getSaleses() {
      // this.isLoading = true

      try {
        const res = await api.GET('/user/sales/active')
        const data = res.data

        if (data) {
          this.saleses = data
          const selectedSale = data.find((s) => s.is_default)
          this.sales_id = selectedSale ? selectedSale.id : ''
        }
      } catch (err) {
        let errmsg = "Sorry, an error occurred while getting the sales";
        const thereIsErrorMessage = err && err.response && err.response.data;

        if (thereIsErrorMessage && err.response.data.message) {
          errmsg = err.response.data.message;
        }

        if (
          thereIsErrorMessage &&
          err.response.data.data !== "record not found"
        ) {
          this.$store.dispatch("HOMEPAGE/showErrorToast", errmsg);
        }
      }

      // this.isLoading = false
    }
  },
  setup(props, context) {
    const Composable = useAction();
    const { save: Save } = Composable;
    const { profile: Profile } = Composable;
    return {
      ...Composable,
      Profile,
      async save() {
        let result = await Save();
        if (result.code == 200) {
          const orgid = result.data.id
          localstorage.setItem('orgid', orgid)
          localstorage.removeItem("need_create_organization");
          this.$router.push("/create-new-project");
        } else {
          console.log(result);
          let response = result.data || result.Data;
          if (!response)
            response =
              "Internal server error. Please contact administrator for more help.";
          this.errorMessage = response;
        }
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.v-card__text {
  ::v-deep .v-btn__content {
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0.02em;
  }
}

.v-input {
  ::v-deep .v-input__slot {
    margin-bottom: 0;
  }
}
</style>