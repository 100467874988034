var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"rounded-lg",attrs:{"flat":""}},[(_vm.listTags.length == 0)?_c('v-container',{staticClass:"px-7 py-4",attrs:{"fluid":""}},[_c('v-card',{staticClass:"rounded-lg",attrs:{"flat":"","outlined":""}},[_c('v-col',{attrs:{"md":"12"}},[_c('v-card',{staticClass:"d-flex rounded-lg flex-grow-1 flex-column text-center ma-auto",attrs:{"flat":""}},[_c('v-card-text',{staticClass:"pb-10 pt-10"},[_c('p',{staticClass:"font-weight-bold title fz-16"},[_vm._v("Looks like you don’t have any Tags")]),_c('v-row',[_c('v-col',[_c('p',{staticClass:"fz-12 mb-5 cl"},[_vm._v("Create firts your Tags")])])],1),_c('v-row',[_c('v-col',[(!_vm.read_only)?_c('v-btn',{staticClass:"secondary fz-14",attrs:{"width":"100%","append":"","max-width":"300","height":"50","depressed":"","exact":"","to":"/manage-pricing/create-tags"}},[_vm._v(" Create Tags ")]):_vm._e()],1)],1)],1)],1)],1)],1)],1):_c('v-container',{staticClass:"px-7 py-4",attrs:{"fluid":""}},[_c('v-row',{staticClass:"d-flex justify-center"},[_c('v-col',{attrs:{"cols":"9"}},[_c('div',{staticClass:"headline font-weight-bold font--text"},[_vm._v("List Tags")])]),_c('v-col',{attrs:{"md":"1"}}),_c('v-col',{attrs:{"md":"2"}},[(!_vm.read_only)?_c('router-link',{attrs:{"to":"/manage-pricing/create-tags"}},[_c('v-btn',{staticClass:"fz-14",attrs:{"block":"","color":"secondary","dark":"","height":"45","width":"150"}},[_vm._v("Create Tags")])],1):_vm._e()],1)],1),_c('v-row',[_c('v-col',{attrs:{"md":"12"}},[_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.listTags,"item-key":"id","single-line":"","loading":_vm.loadingListTags,"hide-default-footer":"","server-items-length":_vm.totalTags},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('v-row',[_c('v-col',[_c('span',[_vm._v(_vm._s(item.name))])])],1)]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.$FormatDate(item.created_at)))])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-switch',{staticClass:"mt-0 mb-2",attrs:{"hide-details":"","true-value":1,"false-value":0,"flat":"","readonly":""},model:{value:(item.status),callback:function ($$v) {_vm.$set(item, "status", $$v)},expression:"item.status"}})]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('popupquote',{attrs:{"offsetTop":20,"coordinate":_vm.coordinate,"documentid":'popup' + item.id,"offset-y":"","allow-overflow":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('label',_vm._b({staticClass:"primary--text linkpointer",staticStyle:{"position":"relative","white-space":"nowrap"},on:{"click":function ($event) {
                            on.click($event);
                            _vm.show($event, item);
                          }}},'label',attrs,false),[_vm._v("More "),_c('v-icon',{class:{
                            rotate: item.id == _vm.activemenuinstance && _vm.popo,
                          },attrs:{"id":'popup' + item.id,"color":"primary"}},[_vm._v("mdi-chevron-down ")])],1)]}}],null,true),model:{value:(_vm.popo),callback:function ($$v) {_vm.popo=$$v},expression:"popo"}},[_c('v-list',{staticClass:"cursor-pointer font-weight-bold",attrs:{"width":"100"}},[_c('v-list-item',{attrs:{"disabled":_vm.read_only}},[_c('v-list-item-title',[_c('span',{staticClass:"primary--text",on:{"click":function () {
                                _vm.dialogEdit = true;
                                _vm.dataTags = item;
                              }}},[_vm._v("Edit")])])],1),_c('v-list-item',{attrs:{"disabled":_vm.read_only}},[_c('v-list-item-title',[_c('span',{staticClass:"red--text",on:{"click":function () {
                                _vm.openDialog = true;
                                _vm.dataTags = item;
                              }}},[_vm._v("Delete")])])],1)],1)],1)]}},{key:"footer",fn:function(ref){
                              var props = ref.props;
return [_c('custom-footer-datatable',{attrs:{"props":props},on:{"input":function (val) {
                        _vm.options = val;
                        _vm.changePage(val);
                      }}})]}}],null,true)})],1)],1)],1)],1)],1)],1),(_vm.openDialog)?_c('deleteTags',{attrs:{"dialog-closed":_vm.openDialog,"data-tags":_vm.dataTags},on:{"update:dialogClosed":function($event){_vm.openDialog=$event},"update:dialog-closed":function($event){_vm.openDialog=$event}}}):_vm._e(),(_vm.dialogEdit)?_c('EditTags',{attrs:{"dialog-edit":_vm.dialogEdit,"data-tags":_vm.dataTags},on:{"update:dialogEdit":function($event){_vm.dialogEdit=$event},"update:dialog-edit":function($event){_vm.dialogEdit=$event}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }