var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"px-0",attrs:{"fluid":""}},[_c('v-card',{staticClass:"pa-7 rounded-lg",attrs:{"flat":""}},[_c('v-row',[_c('v-col',{staticClass:"d-flex flex-row align-center"},[_c('p',{staticClass:"mb-0 font-weight-bold fz-21"},[_vm._v("Manage Organization")]),_c('v-spacer'),_c('v-text-field',{staticStyle:{"max-width":"200px"},attrs:{"maxlength":"50","hide-details":"","outlined":"","placeholder":"Search"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('div',{staticStyle:{"height":"auto","width":"30px"}}),(!_vm.read_only)?_c('v-btn',{staticClass:"secondary",attrs:{"id":"CreateNewOrg","depressed":"","height":"50"},on:{"click":function () {
              _vm.opendialogadd = true;
            }}},[_c('span',{staticClass:"fz-14"},[_vm._v("Create New Organization")])]):_vm._e()],1)],1),_c('v-row',[_c('v-col',[_c('v-data-table',{attrs:{"id":"Table","hide-default-footer":"","items":_vm.organizations ? _vm.organizations : [],"items-per-page":10,"server-items-length":_vm.pagination.count,"headers":_vm.headers,"search":_vm.search,"options":_vm.options,"footer-props":_vm.footerProps},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([(_vm.isDevModePrepaid)?{key:"item.is_personal",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(item.is_personal ? 'Personal' : 'Corporate')+" ")]}}:{key:"item.type",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(["Basic", "Premium", "", "Request Upgrade"][item.org_type])+" ")]}},{key:"item.is_white_label",fn:function(ref){
            var item = ref.item;
return [_c('v-container',{staticClass:"px-0",attrs:{"fluid":""}},[_c('v-chip',{staticStyle:{"text-transform":"capitalize"},attrs:{"color":item.is_white_label ? 'primary' : '',"text-color":item.is_white_label ? 'white' : ''}},[_vm._v(" "+_vm._s(item.is_white_label ? "yes" : "no")+" ")])],1)]}},{key:"item.dekaprime",fn:function(ref){
            var item = ref.item;
return [_c('v-container',{staticClass:"px-0",attrs:{"fluid":""}},[_c('v-chip',{staticStyle:{"text-transform":"capitalize"},attrs:{"color":item.dekaprime_organization_id ? 'primary' : '',"text-color":item.dekaprime_organization_id ? 'white' : ''}},[_vm._v(" "+_vm._s(item.dekaprime_organization_id ? "yes" : "no")+" ")])],1)]}},{key:"item.status",fn:function(ref){
            var item = ref.item;
return [_c('v-switch',{staticClass:"mt-0 mb-2",attrs:{"hide-details":"","true-value":1,"false-value":0,"flat":"","readonly":"","disabled":_vm.read_only},on:{"click":function () {
                  if (_vm.read_only){
                    return
                  }
                  _vm.orgselected = item;
                  _vm.opendialogactivateorg = true;
                }},model:{value:(item.status),callback:function ($$v) {_vm.$set(item, "status", $$v)},expression:"item.status"}})]}},{key:"item.action",fn:function(ref){
                var item = ref.item;
return [_c('popupquote',{attrs:{"offsetTop":21,"documentid":'popup' + item.id,"offset-y":"","allow-overflow":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('label',_vm._b({staticClass:"primary--text linkpointer",staticStyle:{"position":"relative","white-space":"nowrap"},on:{"click":function ($event) {
                      on.click($event);
                      item.open = true;
                    }}},'label',attrs,false),[_vm._v("More "),_c('v-icon',{class:{
                      rotate: item.open,
                    },attrs:{"id":'popup' + item.id,"color":"primary"}},[_vm._v("mdi-chevron-down ")])],1)]}}],null,true),model:{value:(item.open),callback:function ($$v) {_vm.$set(item, "open", $$v)},expression:"item.open"}},[_c('v-list',[(!_vm.isDevModePrepaid)?[(item.org_type != 1)?_c('v-list-item',{on:{"click":function () {
                        _vm.orgselected = item;
                        _vm.opendialogupgradeorg = true;
                      }}},[_c('span',{staticClass:"font--text",staticStyle:{"font-weight":"600"}},[_vm._v(" Upgrade to Premium ")])]):_c('v-list-item',{on:{"click":function () {
                        _vm.orgselected = item;
                        _vm.opendialogdowngrade = true;
                      }}},[_c('span',{staticClass:"font--text",staticStyle:{"font-weight":"600"}},[_vm._v(" Revert to Basic ")])]),_c('v-divider')]:_vm._e(),(!_vm.read_only)?_c('v-list-item',{on:{"click":function () {
                      _vm.orgselected = item;
                      _vm.opendialogedit = true;
                    }}},[_c('span',{staticClass:"font--text",staticStyle:{"font-weight":"600"}},[_vm._v("Edit")])]):_vm._e(),_c('v-list-item',{on:{"click":function () {
                      _vm.$router.push(("/organization-detail/" + (item.id)));
                    }}},[_c('span',{staticClass:"font--text",staticStyle:{"font-weight":"600"}},[_vm._v("Detail")])]),(!_vm.read_only && item.suspend_type)?_c('v-list-item',{on:{"click":function () {
                      _vm.orgselected = item;
                      _vm.opendialogunsuspend = true;
                    }}},[_c('span',{staticClass:"font--text",staticStyle:{"font-weight":"600"}},[_vm._v("Unsuspend")])]):_vm._e(),(!_vm.read_only)?_c('v-list-item',{on:{"click":function () {
                      _vm.orgselected = item;
                      _vm.hapusORG();
                    }}},[_c('v-list-item-title',{staticClass:"error--text"},[_vm._v("Delete ")])],1):_vm._e()],2)],1)]}},{key:"footer",fn:function(ref){
                    var props = ref.props;
return [_c('custom-footer-datatable',{attrs:{"hidden":true,"props":props},on:{"input":function (val) {
                  _vm.options = Object.assign({}, _vm.options, val);
                }}})]}}],null,true)})],1)],1)],1),_c('dialogAddOrg',{on:{"added":function () {
        _vm.fetchorganizations(_vm.options);
      }},model:{value:(_vm.opendialogadd),callback:function ($$v) {_vm.opendialogadd=$$v},expression:"opendialogadd"}}),(_vm.opendialogedit && _vm.orgselected)?_c('dialogAddOrg',{attrs:{"organization":_vm.orgselected},on:{"updated":function () {
        _vm.fetchorganizations(_vm.options);
      }},model:{value:(_vm.opendialogedit),callback:function ($$v) {_vm.opendialogedit=$$v},expression:"opendialogedit"}}):_vm._e(),(_vm.opendialogunsuspend)?_c('dialogUnsuspendOrg',{attrs:{"org":_vm.orgselected},on:{"success":function (){
      _vm.fetchorganizations(_vm.options);
    },"error":function (response) {
      _vm.opendialogunsuspend = false;
      _vm.dialogerrormsg = response.data.data;
      _vm.opendialogerror = true;
    }},model:{value:(_vm.opendialogunsuspend),callback:function ($$v) {_vm.opendialogunsuspend=$$v},expression:"opendialogunsuspend"}}):_vm._e(),(_vm.opendialogdelete)?_c('dialogDeleteOrg',{attrs:{"selectedorg":_vm.orgselected},on:{"close":function () {
        _vm.opendialogdelete = false;
      },"deleteorg":function (){
      _vm.opendialogareyousure = true
    }},model:{value:(_vm.opendialogdelete),callback:function ($$v) {_vm.opendialogdelete=$$v},expression:"opendialogdelete"}}):_vm._e(),_c('dialogUpgradeOrg',{attrs:{"org":_vm.orgselected},on:{"success":function () {
        _vm.fetchorganizations(_vm.options);
        _vm.opendialogupgradeorg = false;
        _vm.opendialogupgradepremiumsuccess = true;
      }},model:{value:(_vm.opendialogupgradeorg),callback:function ($$v) {_vm.opendialogupgradeorg=$$v},expression:"opendialogupgradeorg"}}),_c('dialogDowngradeOrg',{on:{"confirm":function () {
        _vm.downgradeorg(_vm.orgselected).then(function () {
          _vm.opendialogdowngrade = false;
          _vm.fetchorganizations(_vm.options);
        });
      }},model:{value:(_vm.opendialogdowngrade),callback:function ($$v) {_vm.opendialogdowngrade=$$v},expression:"opendialogdowngrade"}}),(_vm.opendialogareyousure)?_c('dialogAreYouSure',{attrs:{"org":_vm.orgselected},on:{"deleted":function (){
    _vm.fetchorganizations(_vm.options);
  },"error":function (response) {
        _vm.opendialogdelete = false;
        _vm.dialogerrormsg = response.data.data;
        _vm.opendialogerror = true;
      }},model:{value:(_vm.opendialogareyousure),callback:function ($$v) {_vm.opendialogareyousure=$$v},expression:"opendialogareyousure"}}):_vm._e(),(_vm.orgselected)?_c('dialogActivateUser',{attrs:{"userorganization":"organization","onoff":_vm.orgselected.status ? 'off' : 'on'},on:{"confirm":function () {
        _vm.changeorgstatus(_vm.orgselected).then(function () {
          _vm.orgselected.status = _vm.orgselected.status ? 0 : 1;
          _vm.opendialogactivateorg = false;
        });
      }},model:{value:(_vm.opendialogactivateorg),callback:function ($$v) {_vm.opendialogactivateorg=$$v},expression:"opendialogactivateorg"}}):_vm._e(),_c('dialogUpgradePermiumSucces',{model:{value:(_vm.opendialogupgradepremiumsuccess),callback:function ($$v) {_vm.opendialogupgradepremiumsuccess=$$v},expression:"opendialogupgradepremiumsuccess"}}),_c('dialogErrorDelete',{attrs:{"message":_vm.dialogerrormsg},model:{value:(_vm.opendialogerror),callback:function ($$v) {_vm.opendialogerror=$$v},expression:"opendialogerror"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }