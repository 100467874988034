var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"rounded-lg elevation-0",attrs:{"flat":""}},[_c('v-container',{staticClass:"px-7 py-4",attrs:{"fluid":""}},[_c('v-row',{staticClass:"d-flex align-center"},[_c('v-col',{attrs:{"cols":"8"}},[_c('div',{staticClass:"headline mb-2 font-weight-bold font--text"},[_vm._v(" NFS ")]),_c('p',{staticStyle:{"color":"#a5b3bf"}},[_vm._v(" Each NFS is for you to share files in a folder that connect on a network. ")])]),_c('v-col',{staticClass:"d-flex justify-end align-center",attrs:{"cols":"4"}},[(_vm.nfs_list.length > 0)?_c('v-btn',{staticClass:"secondary",attrs:{"depressed":"","append":"","width":"150","height":"45"},on:{"click":function () {
                _vm.validateprivilages(['NFS', 'editor', true]).then(function () {
                  _vm.$router.push('network-file-system/create')
                })
              }}},[_vm._v(" Create NFS ")]):_vm._e()],1)],1),(_vm.nfs_list.length > 0)?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-0",attrs:{"hide-default-footer":"","headers":_vm.headers,"items":_vm.nfs_list,"server-items-length":_vm.pagination.itemsLength,"items-per-page":_vm.pagination.itemsPerPage},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_vm._v(" "+_vm._s(_vm.isLoading ? "Loading.." : "No data available")+" ")]},proxy:true},{key:"item.created_at",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$moment(item.created_at).format("DD/MM/YYYY"))+" ")]}},{key:"item.size",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(item.size)+" "+_vm._s(item.unit_size)+" ")]}},{key:"item.action",fn:function(ref){
              var item = ref.item;
return [_c('popupquote',{attrs:{"offsetTop":21,"documentid":'popup' + item.id,"offset-y":"","allow-overflow":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('label',_vm._b({staticClass:"primary--text linkpointer",staticStyle:{"position":"relative","white-space":"nowrap"},on:{"click":function ($event) {
                            on.click($event);
                            _vm.itemSelectedvalue = item;
                          }}},'label',attrs,false),[_vm._v("More "),_c('v-icon',{class:{
                            rotate: item.open,
                          },attrs:{"id":'popup' + item.id,"color":"primary"}},[_vm._v("mdi-chevron-down")])],1)]}}],null,true),model:{value:(item.open),callback:function ($$v) {_vm.$set(item, "open", $$v)},expression:"item.open"}},[_c('v-list',[_c('v-list-item',{attrs:{"to":_vm.itemSelectedvalue.id + '/client',"append":""}},[_c('v-list-item-title',[_vm._v("Detail")])],1),(item.status != 'On Create' && item.status != 'Error While Creating')?_c('v-list-item',{attrs:{"append":""},on:{"click":function () {
                        _vm.selectedItem = item
                        _vm.dialog_resize = !_vm.dialog_resize
                      }}},[_c('v-list-item-title',[_vm._v("Increase Size")])],1):_c('v-list-item',{attrs:{"append":""}},[_c('v-list-item-title',{staticClass:"grey--text"},[_vm._v("Increase Size")])],1),_c('v-divider'),(!_vm.read_only)?_c('v-list-item',{on:{"click":function () {
                            _vm.validateprivilages(['NFS', 'editor', true]).then(function () {
                              _vm.dialog_delete = true;
                              _vm.itemSelectedvalue = item;
                            })
                          }}},[_c('v-list-item-title',{staticClass:"error--text"},[_vm._v("Delete")])],1):_vm._e()],1)],1)]}},{key:"item.name",fn:function(ref){
                          var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                          var on = ref.on;
                          var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-3",attrs:{"color":item.status == 'Created' ? 'primary' : 'red'}},'v-icon',attrs,false),on),[_vm._v("mdi-circle ")]),_c('div',[_c('router-link',{attrs:{"to":'' + item.id,"append":""}},[_c('span',{staticClass:"primary--text"},[_vm._v(_vm._s(item.name))])])],1)]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(item.status == 'On Create' ? 'Creating' : item.status)+" ")])])],1)]}},{key:"footer",fn:function(ref){
                          var props = ref.props;
return [(_vm.nfs_list.length)?_c('custom-footer-datatable',{attrs:{"props":props},on:{"input":function (val) {
                        _vm.options = val;
                        _vm.changePage(_vm.getNFS, val);
                      }}}):_vm._e()]}}],null,false,2033583343)})],1)],1):_c('v-row',[_c('v-col',{attrs:{"md":"12"}},[_c('v-card',{staticClass:"d-flex rounded-lg flex-grow-1 flex-column text-center ma-auto",attrs:{"flat":"","outlined":""}},[_c('v-card-text',{staticStyle:{"padding-top":"72px","padding-bottom":"78px"}},[_c('p',{staticClass:"font-weight-bold",staticStyle:{"font-size":"16px","margin-bottom":"10px","text-transform":"capitalize"}},[_vm._v(" Looks like you don't have any Network File System ")]),_c('v-row',[_c('v-col',[_c('p',{staticStyle:{"font-size":"12px","color":"#a5b3bf","margin-bottom":"20px"}},[_vm._v(" Create unlimited NFS service that lets you to share your files and amounts of data in a folder. Each folder can access by other server. Makes your sharing flow data between server on your project more simply ")])])],1),_c('v-row',[_c('v-col',[_c('v-btn',{staticClass:"secondary",attrs:{"width":"100%","append":"","max-width":"300","height":"50","depressed":"","exact":""},on:{"click":function () {
                        _vm.validateprivilages(['NFS', 'editor', true]).then(function () {
                          _vm.$router.push('network-file-system/create')
                        })
                      }}},[_vm._v(" Create Network File System ")])],1)],1)],1)],1)],1)],1)],1)],1)],1)],1),(_vm.dialog_delete)?_c('v-dialog',{attrs:{"max-width":"500"},model:{value:(_vm.dialog_delete),callback:function ($$v) {_vm.dialog_delete=$$v},expression:"dialog_delete"}},[_c('v-card',[_c('v-card-title',[_c('div',{staticClass:"headline font-weight-bold font--text pb-4"},[_vm._v("Destroy Network File System ?")])]),_c('v-card-text',{staticClass:"pb-0"},[_c('p',[_vm._v("All your data in this server will be deleted without backup. Are you sure?")])]),_c('v-card-actions',[_c('v-row',{staticClass:"ma-2"},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-btn',{attrs:{"color":"accent","disabled":_vm.isLoadingBtn,"block":"","depressed":"","height":"50"},on:{"click":function($event){_vm.dialog_delete = false}}},[_vm._v(" Cancel ")])],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-btn',{attrs:{"color":"error","block":"","height":"50","disabled":_vm.isLoadingBtn,"loading":_vm.isLoadingBtn,"depressed":""},on:{"click":function () {
                  _vm.doDeleteNFS()
                }}},[_vm._v(" Yes ")])],1)],1)],1)],1)],1):_vm._e(),(_vm.dialog_resize)?_c('dialogResize',{attrs:{"nfs_detail":_vm.selectedItem},model:{value:(_vm.dialog_resize),callback:function ($$v) {_vm.dialog_resize=$$v},expression:"dialog_resize"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }