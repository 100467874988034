var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"rounded-lg",attrs:{"flat":""}},[_c('v-container',{staticClass:"pa-7 pb-1",attrs:{"fluid":"","id":"container"}},[_c('div',{staticClass:"expert-title"},[_vm._v(" Create NAT Gateway ")]),_c('div',{staticClass:"vpc-content pt-5 pb-9"},[_vm._v(" Provides a free routing platform that competes directly with other commercially available solutions from well known network providers. ")]),_c('v-row',[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12","md":"6"}},[_c('p',{staticClass:"font-weight-bold"},[_vm._v("Name")]),_c('v-text-field',{attrs:{"maxlength":"50","placeholder":"Insert Name","single-line":"","outlined":"","error-messages":_vm.nameVpcError},model:{value:(_vm.nameVpc),callback:function ($$v) {_vm.nameVpc=$$v},expression:"nameVpc"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('p',{staticClass:"font-weight-bold"},[_vm._v("Description")]),_c('v-textarea',{attrs:{"outlined":"","name":"input-7-4","placeholder":"Insert Description","error-messages":_vm.descriptionError},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"6"}},[_c('p',[_c('b',[_vm._v("Billing Type")])]),_c('v-select',{attrs:{"id":"billingType","placeholder":"Choose Instance Billing Type","items":_vm.billingTypes,"outlined":"","error-messages":_vm.selectedBillingTypeError},model:{value:(_vm.selectedBillingType),callback:function ($$v) {_vm.selectedBillingType=$$v},expression:"selectedBillingType"}})],1)],1),_c('v-row',[(_vm.selectedBillingType == 'Trial')?_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('p',[_c('b',[_vm._v("Trial Voucher")])]),_c('v-select',{attrs:{"id":"trialVoucher","placeholder":"Choose trial voucher to use","error-messages":_vm.$v.selectedTrialVoucher.$errors.length
                  ? _vm.$v.selectedTrialVoucher.$errors[0].$message
                  : '',"items":_vm.vouchers,"item-value":"id","outlined":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
                  var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" - "+_vm._s(item.code)+" ")]}},{key:"item",fn:function(ref){
                  var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" - "+_vm._s(item.code)+" ")]}}],null,false,2375182940),model:{value:(_vm.selectedTrialVoucher),callback:function ($$v) {_vm.selectedTrialVoucher=$$v},expression:"selectedTrialVoucher"}})],1):_vm._e()],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('p',{staticClass:"font-weight-bold"},[_vm._v("Configure IP Range")]),_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"maxlength":"30","placeholder":"IP Prefix","single-line":"","outlined":"","error-messages":_vm.ipVpcError},on:{"change":function () {
                        _vm.$v.ipVpc.$touch();
                      }},model:{value:(_vm.ipVpc),callback:function ($$v) {_vm.ipVpc=$$v},expression:"ipVpc"}})],1),_c('v-col',{staticClass:"pt-0",attrs:{"cols":"6"}},[_c('v-select',{attrs:{"placeholder":"Network Size","items":_vm.prefixes,"error-messages":_vm.prefixVpcError,"outlined":"","single-line":""},model:{value:(_vm.prefixVpc),callback:function ($$v) {_vm.prefixVpc=$$v},expression:"prefixVpc"}})],1)],1)],1)],1),_c('v-row',[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"6","md":"3"}},[_c('v-btn',{staticClass:"accent fz-14",attrs:{"block":"","height":"45","depressed":"","disabled":_vm.isLoadingBtn,"loading":_vm.isLoadingBtn},on:{"click":function($event){return _vm.$router.push('/network/vpc')}}},[_c('span',[_vm._v("Cancel")])])],1),_c('v-col',{staticClass:"pt-0",attrs:{"cols":"7","md":"3"}},[_c('v-btn',{staticClass:"secondary fz-14",attrs:{"block":"","depressed":"","disabled":_vm.isLoadingBtn,"loading":_vm.isLoadingBtn},on:{"click":_vm.createWizard}},[_c('span',[_vm._v("Create NAT Gateway")])])],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }