<template>
  <v-dialog max-width="500" v-model="dialog">
    <v-card class="pt-7">
      <v-card-text>
        <div class="headline font-weight-bold font--text">
          Edit Organization
        </div>
      </v-card-text>
      <v-card-text>
        <p class="titletext">Details</p>
        <v-textarea
          maxlength="255"
          placeholder="Enter Details"
          flat
          outlined
          v-model="details"
          :error-messages="
            $v.details.$errors.length ? $v.details.$errors[0].$message : ''
          "
        ></v-textarea>
      </v-card-text>
      <v-card-text>
        <p class="titletext">Address</p>
        <v-textarea
          maxlength="255"
          placeholder="Enter Address"
          flat
          outlined
          v-model="address"
          :error-messages="
            $v.address.$errors.length ? $v.address.$errors[0].$message : ''
          "
        ></v-textarea>
      </v-card-text>
      <v-card-text>
        <p class="titletext">Phone Number</p>
        <v-text-field
          v-mask="[
            /[1-9]/,
            /[0-9]/,
            /[0-9]/,
            ' ',
            /[0-9]/,
            /[0-9]/,
            /[0-9]/,
            ' ',
            /[0-9]/,
            /[0-9]/,
            /[0-9]/,
            ' ',
            /[0-9]/,
            /[0-9]/,
            /[0-9]/,
          ]"
          outlined
          prefix="+62"
          placeholder="Enter Phone Number"
          v-model="phone"
          :error-messages="
            $v.phone.$errors.length ? $v.phone.$errors[0].$message : ''
          "
        ></v-text-field>
      </v-card-text>
      <v-card-text>
        <p class="titletext">NPWP Number</p>
        <v-text-field
          outlined
          placeholder="Enter NPWP Number"
          v-model="npwp"
          disabled
          :error-messages="
            $v.npwp.$errors.length ? $v.npwp.$errors[0].$message : ''
          "
        ></v-text-field>
      </v-card-text>

      <v-card-text>
        <p class="titletext">Sales</p>

        <v-autocomplete
          v-model="sales_id"
          outlined
          :items="saleses"
          error-messages=""
          :placeholder="isNotSuperadmin ? '-' : 'Select Sales'"
          item-text="fullname"
          item-value="id"
          :disabled="isNotSuperadmin"
          :filled="isNotSuperadmin"
        />
      </v-card-text>

      <v-card-text>
        <v-row>
          <v-col
            ><v-btn
              @click="
                () => {
                  $emit('input', false);
                }
              "
              depressed
              height="45"
              block
              class="accent"
              >Cancel</v-btn
            ></v-col
          >
          <v-col
            ><v-btn
              @click="
                () => {
                  submit();
                }
              "
              depressed
              height="45"
              block
              class="secondary"
              >Update</v-btn
            ></v-col
          >
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { ref } from "@vue/composition-api";
import {
  useNamespacedActions,
  useNamespacedMutations,
} from "vuex-composition-helpers";
import { SUPERADMIN } from "../superadmin/namespace";
import { SUPERADMINMANAGEORG } from "../superadmin-manageorganization/namespace";
import { useDialog } from "@/composable/usedialog";
import localstorage from "@/lib/localstorage";
import { ORGANIZATION } from "./namespace";
import useVuelidate from "@vuelidate/core";
import { required, requiredIf } from "@vuelidate/validators";
import api from "@/lib/api";

export default {
  props: ["value", "organization"],
  setup(props, context) {
    const { dialog } = useDialog(props, context);
    const details = ref(props.organization.details);
    const address = ref(props.organization.address);
    const phone = ref(props.organization.phone_number_org.replace("+62", ""));
    const npwp = ref(props.organization.npwp_corporate);
    const sales_id = ref(props.organization.sales_id);
    const is_personal = ref(props.organization.is_personal);
    const { setCurrentOrg, setCurrentProj } = useNamespacedMutations(
      SUPERADMIN,
      ["setCurrentOrg", "setCurrentProj"]
    );
    const user_role = localstorage.getItem("role");
    const store =
      user_role == "Superadmin" ? SUPERADMINMANAGEORG : ORGANIZATION;
    const { editorganization } = useNamespacedActions(store, [
      "editorganization",
    ]);
    const $v = useVuelidate(
      {
        details: { required },
        address: { required },
        phone: { required },
        npwp: {
          required: requiredIf((val) => {
            return !is_personal.value;
          }),
        },
        sales_id: { required },
      },
      {
        details,
        address,
        phone,
        npwp,
        sales_id,
      }
    );
    const { setopendialogcommonsuccess } = useNamespacedMutations("DASHBOARD", [
      "setopendialogcommonsuccess",
    ]);
    const submit = () => {
      $v.value.$touch();
      if ($v.value.$errors.length) return;

      const Value =
        user_role == "Superadmin"
          ? {
              id: props.organization.id,
              details: details.value,
              address: address.value,
              phone_number_org: "+62" + phone.value.replaceAll(" ", ""),
              // npwp_corporate: npwp.value
              sales_id: sales_id.value,
            }
          : {
              id: props.organization.id,
              details: details.value,
              address: address.value,
              phone_number: "+62" + phone.value.replaceAll(" ", ""),
              // npwp_corporate: npwp.value
              sales_id: sales_id.value,
            };
      return editorganization(Value).then(() => {
        dialog.value = false;
        context.emit("updated");
        setopendialogcommonsuccess(true);
      });
    };
    return {
      $v,
      details,
      address,
      phone,
      submit,
      npwp,
      sales_id,
      dialog,
    };
  },
  data() {
    return {
      saleses: [],
    };
  },
  computed: {
    isNotSuperadmin() {
      const role = localstorage.getItem("role");
      return role !== "Superadmin";
    },
  },
  methods: {
    async getSaleses() {
      // this.isLoading = true

      try {
        const res = await api.GET("/user/sales/active");
        const data = res.data;

        if (data) {
          this.saleses = data;
        }
      } catch (err) {
        let errmsg = "Sorry, an error occurred while getting the sales";
        const thereIsErrorMessage = err && err.response && err.response.data;

        if (thereIsErrorMessage && err.response.data.message) {
          errmsg = err.response.data.message;
        }

        if (
          thereIsErrorMessage &&
          err.response.data.data !== "record not found"
        ) {
          this.$store.dispatch("HOMEPAGE/showErrorToast", errmsg);
        }
      }

      // this.isLoading = false
    },
  },
  mounted() {
    this.getSaleses();
  },
};
</script>

<style lang="scss" scoped>
.titletext {
  font-weight: 700;
}
.v-input--is-disabled {
  ::v-deep .v-input__slot {
    background: #f1f2f2;
  }
}
</style>