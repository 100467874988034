import { useNamespacedActions, useNamespacedGetters } from "vuex-composition-helpers";

export const by = [ 'service', 'item' ] as const
export type By = typeof by[number]

export const services = [
	"INSTANCE",
	"OS_CHARGING",
	"ROOT_DISK",
	"STORAGE",
	"OBJECT_STORAGE",
	"SNAPSHOT",
	"FLOATING_IP",
	"SLB",
	"DAILY_BACKUP",
	"MANAGE_SERVICE",
	"MANAGE_SERVICE_ADDON",
] as const

export type Service = typeof services[number]

export type Tag = {
  name: string 
  qty: number
}

export type ByService = {
  service_name: Service,
  id: string
  "tags": Tag[]
}

export type ByItem = {
  service_name: Service,
  id: string
}

export type Item = {
  project_id: string
  by: 'service' | 'item'
  items: ByService[] | ByItem[]
}

export const useNewServiceAvailability = () => {
  const { checkNewServiceAvailability } = useNamespacedActions('PROJECT', ["checkNewServiceAvailability"]);
  const { newServiceAvailability, isCheckNewServiceLoading, getprojectid } = useNamespacedGetters('PROJECT', ["newServiceAvailability", "getprojectid"]);

  const checkAvailability = async(by: By, items: Item) => {

    const payload  = {
      project_id: getprojectid.value,
      by: by,
      items: items,
    }
    
    await checkNewServiceAvailability(payload);
  }

  return {
    checkAvailability,
    newServiceAvailability,
    isCheckNewServiceLoading,
  }
}

export default useNewServiceAvailability