var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"800"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('div',{staticClass:"headline font-weight-bold font--text pb-4"},[_vm._v("Resize NFS size")])]),_c('v-card-subtitle',{staticClass:"rounded-lg",attrs:{"flat":""}},[_c('v-row',[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12"}},[_c('p',[_c('span',{staticClass:"font-weight-bold mb-0"},[_vm._v("Select Volume Size")]),(_vm.$v.selectedFlavor.$errors.length)?_c('span',{staticClass:"ml-2",class:{
              'error--text': _vm.$v.selectedFlavor.$errors.length,
            },staticStyle:{"font-size":"10px","position":"absolute"}},[_vm._v(" *"+_vm._s(_vm.$v.selectedFlavor.$errors[0].$message))]):_vm._e(),(_vm.$v.customQuota.$errors.length)?_c('span',{staticClass:"ml-2",class:{
              'error--text': _vm.$v.customQuota.$errors.length,
            },staticStyle:{"font-size":"10px","position":"absolute"}},[_vm._v(" *"+_vm._s(_vm.$v.customQuota.$errors[0].$message))]):_vm._e()])]),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12"}},[_c('v-slide-group',{staticClass:"py-0",attrs:{"active-class":"success","show-arrows":""}},[_c('v-col',{staticClass:"d-flex flex-row flex-wrap pb-0"},[_c('v-card',{staticClass:"rounded-lg d-flex flex-column select-storage mb-4",class:{
                  bordererror: _vm.$v.selectedFlavor.$errors.length,
                  highlight: _vm.isCustomQuotaHighlighted,
                  selected: _vm.isCustomQuotaHighlighted,
                },staticStyle:{"text-align":"center","margin-right":"20px"},attrs:{"tabindex":"0","min-width":"120","border-color":"primary","outlined":""},on:{"click":function () {
                    _vm.isCustomQuotaHighlighted = true;
                    _vm.selectedFlavor = null;
                  }}},[_c('v-card-text',{staticClass:"pb-0 pt-4 d-flex flex-column align-start m-0"}),_c('v-card-text',{staticClass:"pa-0 mt-auto"},[_c('v-text-field',{staticClass:"px-4 pt-2 mt-2 label-bold",staticStyle:{"width":"140px"},attrs:{"maxlength":"50","flat":"","suffix":"GB","label":"Enter Size"},model:{value:(_vm.customQuota),callback:function ($$v) {_vm.customQuota=$$v},expression:"customQuota"}})],1)],1),_vm._l((_vm.flavor_list.filter(function (x) { return x.size > _vm.nfs_detail.size; })),function(flavor){return _c('v-card',{key:flavor.id,staticClass:"rounded-lg select-storage mb-4",class:{
                  bordererror: _vm.$v.selectedFlavor.$errors.length,
                  highlight: _vm.selectedFlavor && _vm.selectedFlavor.id == flavor.id,
                  selected: _vm.selectedFlavor && _vm.selectedFlavor.id == flavor.id,
                },staticStyle:{"text-align":"center","margin-right":"20px"},style:({border: _vm.$v.selectedFlavor.$errors.length ? '1px solid #eb5757 !important' : ''}),attrs:{"width":"120","border-color":"primary","outlined":""},on:{"click":function () {
                  _vm.selectedFlavor = flavor
                  _vm.isCustomQuotaHighlighted = false
                  _vm.customQuota = null
                }}},[_c('v-card-text',{staticClass:"d-flex flavor-card mt-2"},[_c('p',{staticClass:"mb-0"},[_vm._v(_vm._s(flavor.size)+" GB")])])],1)})],2)],1)],1)],1)],1),_c('v-card-actions',[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-btn',{attrs:{"color":"accent","block":"","depressed":"","height":"50","disabled":_vm.resizeLoading},on:{"click":function($event){_vm.dialog = false}}},[_vm._v("Cancel")])],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-btn',{attrs:{"disabled":_vm.resizeLoading,"loading":_vm.resizeLoading,"color":"primary","block":"","height":"50","depressed":""},on:{"click":function () {_vm.resize()}}},[_vm._v(" Save ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }